import { USER_TYPE } from 'models';
import { theme } from 'theme';

export const isRefreshing = 'isRefreshing';
export const INIT_COUNTRY_CODE = { code: '+65', name: 'sg' };

export const REGEX = {
  OTP: /^[0-9]{6}$/,
};

export const PHOTO_SIZE = 5 * 1024 * 1024; // bytes 5242880
export const TOTAL_TIME_EXPIRED_QRCODE = 60 * 5; // 5mins

export const USER_COLOR: { [key in USER_TYPE]: { main: string } } = {
  [USER_TYPE.PARENT]: { main: theme.palette.primary.main },
  [USER_TYPE.NANNY]: { main: theme.palette.secondary.main },
};

export const TOKEN_KEY = {
  ACCESS_TOKEN: 'ACCESS_TOKEN_CONCIERGE_WEB',
  REFRESH_TOKEN: 'REFRESH_TOKEN_CONCIERGE_WEB',
  REGISTER: {
    ACCESS_TOKEN: 'PARENT_REGISTER_ACCESS_TOKEN_CONCIERGE_WEB',
    REFRESH_TOKEN: 'PARENT_REGISTER_REFRESH_TOKEN_CONCIERGE_WEB',
  },
  FORGOT_PASSWORD: {
    ACCESS_TOKEN: 'PARENT_FORGOT_PASSWORD_ACCESS_TOKEN_CONCIERGE_WEB',
    REFRESH_TOKEN: 'PARENT_FORGOT_PASSWORD_REFRESH_TOKEN_CONCIERGE_WEB',
  },
};
