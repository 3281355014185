/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  InputAdornment,
  InputBaseComponentProps,
  Tooltip,
} from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { HelpIcon } from 'assets';
import StripeInputField from '../StripeInputField';
import { StyledStripeTextField } from './styles';

type StripeElement =
  | typeof CardCvcElement
  | typeof CardExpiryElement
  | typeof CardNumberElement;

interface StripeCardCVCFieldProps<T extends StripeElement>
  extends Omit<TextFieldProps, 'onChange' | 'inputComponent' | 'inputProps'> {
  inputProps?: InputBaseComponentProps;
  labelErrorMessage?: string;
  onChange?: any;
  stripeElement?: T;
  tooltipText?: string;
  isHelpIcon?: boolean;
}

export const StripeTextField = <T extends StripeElement>(
  props: StripeCardCVCFieldProps<T>,
) => {
  const {
    helperText,
    InputLabelProps,
    InputProps = {},
    inputProps,
    error,
    labelErrorMessage,
    stripeElement,
    onChange: externalOnChange,
    tooltipText = '',
    isHelpIcon = false,
    ...propsOther
  } = props;

  return (
    <StyledStripeTextField
      variant="filled"
      fullWidth
      error={error}
      helperText={error ? labelErrorMessage : helperText}
      onChange={(complete: any, error: any) =>
        externalOnChange?.(complete, error)
      }
      InputLabelProps={{
        ...InputLabelProps,
      }}
      InputProps={{
        ...InputProps,
        inputProps: {
          ...inputProps,
          ...InputProps.inputProps,
          component: stripeElement,
        },
        inputComponent: StripeInputField,
        endAdornment: isHelpIcon ? (
          <Tooltip
            title={tooltipText ?? ''}
            arrow
            disableFocusListener
            disableHoverListener
            disableTouchListener
            PopperProps={{
              disablePortal: true,
            }}
          >
            <InputAdornment
              position="end"
              sx={{ zIndex: 1, cursor: 'pointer' }}
            >
              <HelpIcon />
            </InputAdornment>
          </Tooltip>
        ) : undefined,
      }}
      {...(propsOther as any)}
    />
  );
};

export function StripeCardNumberField(
  props: StripeCardCVCFieldProps<typeof CardNumberElement>,
) {
  return (
    <StripeTextField
      placeholder="Card Number"
      stripeElement={CardNumberElement}
      {...props}
    />
  );
}

export function StripeCardExpiryField(
  props: StripeCardCVCFieldProps<typeof CardExpiryElement>,
) {
  return (
    <StripeTextField
      placeholder="Expiry MM/YY"
      stripeElement={CardExpiryElement}
      {...props}
    />
  );
}

export function StripeCardCVCField(
  props: StripeCardCVCFieldProps<typeof CardCvcElement>,
) {
  return (
    <StripeTextField
      placeholder="CVV"
      stripeElement={CardCvcElement}
      isHelpIcon
      tooltipText="CVVVV"
      {...props}
    />
  );
}
