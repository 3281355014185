import { APIInstance } from 'config';
import { API } from 'constants/apiEndpoints';
import { PAGINATION2_TYPE, RESPONSE_TYPE } from 'models';
import {
  GET_SUBSCRIPTION_PURCHASED_PARAMS,
  SUBSCRIPTION_PURCHASED_RESPONSE,
} from 'models/credits';

export const creditsApi = {
  getCurrentBalanceCredit: async (id: string) => {
    return APIInstance.get<RESPONSE_TYPE<number>>(
      `${API.CREDITS.GET_BALANCE(id)}`,
    );
  },
  getPackagesAvailable: async (data?: any): Promise<any> => {
    return APIInstance.get(API.CREDITS.GET_PACKAGES, data);
  },
  getSubscriptionPurchased: async (
    params: GET_SUBSCRIPTION_PURCHASED_PARAMS,
  ): Promise<
    RESPONSE_TYPE<PAGINATION2_TYPE<SUBSCRIPTION_PURCHASED_RESPONSE[]>>
  > => {
    return APIInstance.get(API.CREDITS.SUBSCRIPTION_PURCHASE, {
      params,
    });
  },
};
