import moment from 'moment';

export const sleep = (ms: number) => {
  return new Promise((resole) => setTimeout(resole, ms));
};

export const truncateString = (input: string, numberOfCharacters: number) =>
  input.length > numberOfCharacters
    ? `${input.substring(0, numberOfCharacters)}...`
    : input;

export const pluralString = (input: string, count: number) =>
  count > 1
    ? `${count} ${input}s`
    : count === 0
    ? `${input}`
    : `${count} ${input}`;

export const toCapitalize = (str: string) => {
  if (!str) return '';
  const strToLowerCase = str.toLocaleLowerCase();
  return strToLowerCase.charAt(0).toUpperCase() + strToLowerCase.slice(1);
};

export const convertTimeToUTC = (date: Date) => {
  if (isNaN(date.getTime())) return null;
  return date.toISOString();
};

export const convertTimeToLocal = (date: Date) => {
  if (isNaN(date.getTime())) return null;
  return new Date(date);
};

export const mergeDateTime = (date: moment.Moment, time: Date) => {
  return new Date(
    `${date?.format('YYYY/MM/DD')} ${moment(time)?.format('HH:mm:ss')}`,
  );
};

export enum COMPARE_DATE {
  IS_SAME = 'IS_SAME',
  IS_BEFORE = 'IS_BEFORE',
  IS_AFTER = 'IS_AFTER',
}

export const compareDateTime = (d1: Date, d2: Date) => {
  const time1 = d1.getTime();
  const time2 = d2.getTime();

  switch (true) {
    case time1 < time2:
      return COMPARE_DATE.IS_BEFORE;
    case time1 > time2:
      return COMPARE_DATE.IS_AFTER;
    default:
      return COMPARE_DATE.IS_SAME;
  }
};

// Main function to format the price
export const formatPrice = (price: number | undefined): string => {
  // Handle undefined or invalid input
  if (price === undefined || isNaN(price)) return '$0.00';

  // Round a number to the nearest cent (two decimal places)
  const roundToNearestCent = (price: number): number =>
    Math.round(price * 100) / 100;

  // Format a number with two decimal places and add a dollar sign
  const formatWithTwoDecimals = (price: number): string =>
    `${price.toFixed(2)}`;

  // Round the price to the nearest cent and format
  return `$${formatWithTwoDecimals(roundToNearestCent(price))}`;
};

export const isUndefined = (value: string) => {
  return !value || value === 'undefined';
};
