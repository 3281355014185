import { ParentAuthLayout } from 'components';
import { Helmet } from 'react-helmet-async';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import PublicRoute from 'routers/PublicRoute';
import { APP_ROUTES } from 'routers/routes';
import ConfirmVerify from './pages/ConfirmVerify';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import PhoneVerify from './pages/PhoneVerify';
import Register from './pages/Register';
import ResetPassword from './pages/ResetPassword';

const configRoutes: RouteObject[] = [
  {
    path: '*',
    element: (
      <PublicRoute>
        <ParentAuthLayout />
      </PublicRoute>
    ),
    children: [
      {
        path: APP_ROUTES.AUTH.LOGIN.path,
        element: (
          <>
            <Login />
            <Helmet>
              <title>
                Connecting Parents to Trustworthy Confinement Nannies | Aunty
              </title>
              <meta
                name="description"
                content="Aunty Confinement Parent Login Page"
              />
            </Helmet>
          </>
        ),
      },
      {
        path: APP_ROUTES.AUTH.SIGN_UP.path,
        element: (
          <>
            <Register />
            <Helmet>
              <title>Aunty Confinement Parent Sign Up Page</title>
              <meta
                name="description"
                content="Confinement Sitter (Parent) Registration Page | Aunty SG"
              />
            </Helmet>
          </>
        ),
      },
      {
        path: APP_ROUTES.AUTH.VERIFY.path,
        element: <PhoneVerify />,
      },
      {
        path: APP_ROUTES.AUTH.FORGOT_PASSWORD.path,
        element: (
          <>
            <ForgotPassword />
            <Helmet>
              <title>Aunty Confinement Parent Forgot Password Page</title>
              <meta
                name="description"
                content="Confinement Sitter (Parent) Forgot Password Page | Aunty SG"
              />
            </Helmet>
          </>
        ),
      },
      {
        path: APP_ROUTES.AUTH.RESET_PASSWORD.path,
        element: <ResetPassword />,
      },
      {
        path: APP_ROUTES.AUTH.VERIFIED.path,
        element: <ConfirmVerify />,
      },
      {
        path: '*',
        element: <Navigate to={APP_ROUTES.AUTH.LOGIN.to} replace />,
      },
    ],
  },
];

function AuthFeature() {
  const routes = useRoutes(configRoutes);
  return <>{routes}</>;
}

export default AuthFeature;
