import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  GET_USER_PROFILE_PARAMS,
  PayloadActionData,
  RESPONSE_TYPE,
  USER_PROFILE,
} from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { profileApi } from 'services';
import { setParentProfile } from 'store/reducers/auth';
import { GET_USER_PROFILE_REQUEST } from 'store/reducers/auth/actionTypes';

function* getUserProfileRequest(
  action: PayloadAction<PayloadActionData<GET_USER_PROFILE_PARAMS>>,
) {
  try {
    const {
      data: { data: parent },
    }: AxiosResponse<RESPONSE_TYPE<USER_PROFILE>> = yield call(
      profileApi.getProfile,
    );
    yield put(setParentProfile(parent));
    action.payload.onSuccess?.();
  } catch (error) {
    action.payload.onFailed?.(error);
  } finally {
    action.payload.onFinally?.();
  }
}

function* getUserProfile() {
  yield takeLatest(GET_USER_PROFILE_REQUEST, getUserProfileRequest);
}
export default getUserProfile;
