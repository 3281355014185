import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { theme } from 'theme';
import { transientOptions } from 'utils/transientOptions';

export const StyledNavLink = styled(NavLink, transientOptions)`
  text-decoration: none;
  padding: 10px 24px 8px 24px;
  background-color: ${theme.palette.background.light};
  border-radius: 5px;
  color: ${theme.palette.grey2.dark};
  &.active {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.white.light};
  }
`;

export const StyledNavLinkDisable = styled(Typography)`
  text-decoration: none;
  padding: 10px 24px 8px 24px;
  background-color: ${theme.palette.background.light};
  border-radius: 5px;
  color: ${theme.palette.grey2.dark};
  opacity: 0.5;
`;
