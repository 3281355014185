import { APIInstance } from 'config';
import { API } from 'constants/apiEndpoints';
import { GET_LIST_JOB_PARAMS, JOB_LIST_RESPONSE, RESPONSE_TYPE } from 'models';

export const jobApi = {
  getList: async (params: GET_LIST_JOB_PARAMS) => {
    return APIInstance.get<RESPONSE_TYPE<JOB_LIST_RESPONSE>>(
      `${API.BOOKING.LIST}`,
      {
        params,
      },
    );
  },
  getDetail: async (id: string) => {
    return APIInstance.get<RESPONSE_TYPE<any>>(`${API.BOOKING.DETAIL(id)}`);
  },
};
