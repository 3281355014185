import { APIInstance } from 'config';
import { API } from 'constants/apiEndpoints';
import { ACTION_TYPE, RESPONSE_TYPE } from 'models';
import { OVERVIEW_RESPONSE } from 'models/overview';

export const overviewApi = {
  getDataOverview: () => {
    return APIInstance.get<RESPONSE_TYPE<OVERVIEW_RESPONSE>>(
      API.OVERVIEW.GET_OVERVIEW,
    );
  },
  updateStatusSitter: (id: string, status: ACTION_TYPE) => {
    return APIInstance.patch<RESPONSE_TYPE>(
      API.OVERVIEW.UPDATE_STATUS_SITTER(id),
      {
        status,
      },
    );
  },
};
