import styled from '@emotion/styled';
import { Stack, Typography } from '@mui/material';
import { theme } from 'theme';

export const StyledCertificationsItem = styled(Stack)`
  width: 100%;
  border-radius: 5px;
  border: 1px solid #959595;
  background: ${theme.palette.white.light};
  padding: 15px 20px;
`;
export const StyledItemTypography = styled(Typography)`
  color: rgba(84, 84, 84, 1);
  &:not(:last-child) {
    margin-right: 5px;
  }
  &:before {
    content: '\u2022';
    margin-right: 5px;
  }
`;
