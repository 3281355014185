import { Grid } from '@mui/material';
import { APP_IMG } from 'assets';
import { Outlet } from 'react-router-dom';
import { Content, RightBox } from './styles';

export const ParentAuthLayout = () => {
  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        md={6}
        xs={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Content>
          <Outlet />
        </Content>
      </Grid>
      <RightBox
        item
        md={6}
        sx={{
          display: { xs: 'none', md: 'flex' },
        }}
      >
        <img src={APP_IMG.authLayout} alt="layout" />
      </RightBox>
    </Grid>
  );
};

export default ParentAuthLayout;
