import { SvgIcon, SvgIconProps } from '@mui/material';

export function HugeIcon({ sx = {}, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      sx={{ ...sx, fill: 'none' }}
    >
      <g clipPath="url(#clip0_29_1820)">
        <path
          d="M8.00016 5.33301V5.99967M8.00016 7.66634V10.6663M8.00016 14.6663C11.6821 14.6663 14.6668 11.6816 14.6668 7.99967C14.6668 4.31778 11.6821 1.33301 8.00016 1.33301C4.31827 1.33301 1.3335 4.31778 1.3335 7.99967C1.3335 11.6816 4.31827 14.6663 8.00016 14.6663Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_29_1820">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default HugeIcon;
