import { APIInstance } from 'config';
import { API } from 'constants/apiEndpoints';
import { RESPONSE_TYPE } from 'models';
import { REVIEW_TYPE, SITTER_TYPE } from 'models/sitter';

export const sitterApi = {
  getDetail: (id: string) => {
    return APIInstance.get<RESPONSE_TYPE<SITTER_TYPE>>(
      `${API.SITTER.DETAIL}/${id}`,
    );
  },
  getReview: (params: { user_id: string }) => {
    return APIInstance.get<RESPONSE_TYPE<REVIEW_TYPE>>(API.SITTER.REVIEWS, {
      params,
    });
  },
};
