import { GET_LIST_JOB_PARAMS, PayloadActionData } from 'models';

export const GET_JOB_LIST_REQUEST = 'GET_JOB_LIST_REQUEST';
export const GET_JOB_DETAIL_REQUEST = 'GET_JOB_DETAIL_REQUEST';

export const getJobListAction = (
  payload: PayloadActionData<GET_LIST_JOB_PARAMS>,
) => ({
  type: GET_JOB_LIST_REQUEST,
  payload,
});

export const getJobDetailAction = (
  payload: PayloadActionData<{ id: string }>,
) => ({
  type: GET_JOB_DETAIL_REQUEST,
  payload,
});
