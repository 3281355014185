import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { NotifyService } from 'config';
import { PayloadActionData, RESPONSE_TYPE } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { paymentApi } from 'services/payment';
import { setGlobalLoading } from 'store/reducers/global';
import { updatePaymentIntentId } from 'store/reducers/payments';
import {
  PAY_FOR_NORMAL_JOB_REQUEST,
  PAY_FOR_TRIAL_JOB_REQUEST,
} from 'store/reducers/payments/actionTypes';
import { PAYMENT_RESPONSE, TRIAL_JOB_PAYLOAD } from '../../../models/payments';

function* payForTrialJobRequest(
  action: PayloadAction<PayloadActionData<TRIAL_JOB_PAYLOAD>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<PAYMENT_RESPONSE>> = yield call(
      paymentApi.trialJob,
      action.payload.data,
    );
    action.payload.onSuccess?.(response.data.data);
    yield put(updatePaymentIntentId(response.data.data.id));
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* payForNormalJobRequest(
  action: PayloadAction<PayloadActionData<TRIAL_JOB_PAYLOAD>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<PAYMENT_RESPONSE>> = yield call(
      paymentApi.normalJob,
      action.payload.data,
    );
    action.payload.onSuccess?.(response.data.data);
    yield put(updatePaymentIntentId(response.data.data.id));
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* paymentForJobSaga() {
  yield takeLatest(PAY_FOR_TRIAL_JOB_REQUEST, payForTrialJobRequest);
  yield takeLatest(PAY_FOR_NORMAL_JOB_REQUEST, payForNormalJobRequest);
}
export default paymentForJobSaga;
