import { Avatar, Button, Divider, Stack, Typography } from '@mui/material';
import { ArrowLeftIcon } from 'assets';
import { formatDate } from 'helpers';
import { ACTION_TYPE, JOB_STATUS, MODAL_ACTION } from 'models';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getJobDetailAction } from 'store/reducers/job/actionTypes';
import { updateDataPaymentJob } from 'store/reducers/payments';
import { theme } from 'theme';
import { pluralString, toCapitalize } from 'utils';
import AcceptExtensionReductionModal from '../../components/AcceptExtensionReductionModal';
import {
  mapBookingColor,
  mapBookingTitle,
  mapButtonTitle,
} from '../../helpers';
import {
  StyledBackgroundOverlay,
  StyledBookingDetailWrapper,
  StyledBoxChildrenWrapper,
  StyledContentWrapper,
  StyledJobWrapper,
  StyledSitterWrapper,
} from './styles';

function JobDetail() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { jobDetail } = useAppSelector((state) => state.jobs);
  const [modalAction, setModalAction] = useState<MODAL_ACTION<JOB_STATUS>>();

  const { id } = useParams();

  const handlePaymentJob = () => {
    dispatch(
      updateDataPaymentJob({
        id: jobDetail.id,
        total: jobDetail.total,
        isTrial: jobDetail.is_trial_job,
      }),
    );
    navigate(APP_ROUTES.BOOKING.PAYMENT.to);
  };

  useEffect(() => {
    dispatch(
      getJobDetailAction({
        data: { id },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!jobDetail) return;
  return (
    <StyledBookingDetailWrapper spacing={2}>
      <StyledBackgroundOverlay>
        <NavLink to={APP_ROUTES.BOOKING.to}>
          <ArrowLeftIcon sx={{ color: theme.palette.white.light }} />
          <Typography lineHeight="21px" variant="Web_Label_16">
            Back to Bookings
          </Typography>
        </NavLink>
      </StyledBackgroundOverlay>
      <StyledContentWrapper gap={2}>
        <StyledJobWrapper spacing={2}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={{ xs: 'start', md: 'space-between' }}
            spacing={{ xs: 4, md: 0 }}
          >
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={{ xs: 1, md: 0.5 }}
            >
              <Typography variant="Web_Title_18">{jobDetail.number}</Typography>
            </Stack>
            <Typography
              variant="Web_Label_16"
              color={mapBookingColor[jobDetail.status as JOB_STATUS]}
            >
              {mapBookingTitle[jobDetail.status as JOB_STATUS]}
            </Typography>
          </Stack>
          <Divider />
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={{ xs: 4, md: 14 }}
          >
            <Stack spacing={{ xs: 0.5 }}>
              <Typography
                variant="Web_Label_16"
                color={theme.palette.grey3.dark}
              >
                Start Date
              </Typography>
              <Typography variant="Web_Label_16">
                {formatDate(jobDetail.start_datetime, {
                  format: 'DD/MM/YYYY (ha)',
                })}
              </Typography>
            </Stack>
            <Stack spacing={{ xs: 0.5 }}>
              <Typography
                variant="Web_Label_16"
                color={theme.palette.grey3.dark}
              >
                End Date
              </Typography>
              <Typography variant="Web_Label_16">
                {formatDate(jobDetail.end_datetime, {
                  format: 'DD/MM/YYYY (ha)',
                })}
              </Typography>
            </Stack>
            <Stack spacing={{ xs: 0.5 }}>
              <Typography
                variant="Web_Label_16"
                color={theme.palette.grey3.dark}
              >
                Duration
              </Typography>
              <Typography variant="Web_Label_16">
                {pluralString('hour', jobDetail.total_hours)}
              </Typography>
            </Stack>
          </Stack>
          <Stack spacing={0.5}>
            <Typography variant="Web_Label_16" color={theme.palette.grey3.dark}>
              Address
            </Typography>
            <Typography variant="Web_Label_16" textTransform={'capitalize'}>
              {`${jobDetail.address.block_number} ${jobDetail.address.street}, #${jobDetail.address.unit_number} ${jobDetail.address.Region.name} ${jobDetail.address.postal_code}`}
            </Typography>
          </Stack>
          <StyledBoxChildrenWrapper>
            {jobDetail.children.map((item) => (
              <Stack
                key={item.id}
                direction={'row'}
                alignItems={'center'}
                spacing={1}
              >
                <Avatar className="avtChildren">
                  {item.full_name.charAt(0)}
                </Avatar>
                <Stack>
                  <Typography className="nameChildren">
                    {item.full_name}
                  </Typography>
                  <Typography className="ageChildren">
                    {item.age_range} years old
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </StyledBoxChildrenWrapper>
          <Divider />
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={'space-between'}
            alignItems={'center'}
            spacing={{ xs: 0.5, md: 0 }}
          >
            <Typography variant="Web_Label_16" color={theme.palette.grey3.dark}>
              Created on:&nbsp;
              {formatDate(jobDetail.createdAt, {
                format: 'DD/MM/YYYY',
              })}
            </Typography>
            <Stack direction={'row'} spacing={1}>
              {![
                JOB_STATUS.CANCELLED,
                JOB_STATUS.STARTED,
                JOB_STATUS.PENDING_COMPLETED,
                JOB_STATUS.COMPLETED,
              ].includes(jobDetail.status) && (
                <>
                  <Button
                    variant="text"
                    sx={{ height: 48 }}
                    onClick={(
                      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                    ) => {
                      e.preventDefault();
                      console.log('Cancelled');
                    }}
                  >
                    Cancel Booking
                  </Button>
                  {!jobDetail.is_paid ? (
                    <Button
                      variant="contained"
                      onClick={(
                        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                      ) => {
                        e.preventDefault();
                        handlePaymentJob();
                        mapButtonTitle[jobDetail.status] === 'View Change' &&
                          setModalAction({ type: ACTION_TYPE.CUSTOM });
                      }}
                    >
                      {mapButtonTitle[jobDetail.status as JOB_STATUS]}
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      sx={{ height: 48 }}
                      onClick={(
                        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                      ) => {
                        e.preventDefault();
                        navigate(APP_ROUTES.BOOKING.EDIT.to(jobDetail.id));
                      }}
                    >
                      {'Edit booking'}
                    </Button>
                  )}
                </>
              )}
            </Stack>
          </Stack>
        </StyledJobWrapper>
        <StyledSitterWrapper>
          <Typography className="sitterTitle">Sitter</Typography>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'row'} spacing={1.5} alignItems="center">
              <img
                src={jobDetail.Sitter.profile_picture}
                alt=""
                className="sitterPicture"
              />
              <Stack>
                <Typography className="sitterName">
                  {toCapitalize(jobDetail.Sitter.User.first_name) +
                    ' ' +
                    toCapitalize(jobDetail.Sitter.User.last_name)}
                </Typography>
                <Typography className="totalExp">
                  Total Exp on App: {jobDetail.total_hours} Hours
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Typography
                variant="Web_Label_14"
                color={theme.palette.red.light}
              >
                {toCapitalize(jobDetail.status)}
              </Typography>
              <Button
                variant="contained"
                onClick={() =>
                  navigate(APP_ROUTES.SITTER_DETAIL.to(jobDetail.sitter_id))
                }
              >
                View
              </Button>
            </Stack>
          </Stack>
        </StyledSitterWrapper>
      </StyledContentWrapper>
      {modalAction?.type === ACTION_TYPE.CUSTOM && (
        <AcceptExtensionReductionModal _onClose={() => {}} />
      )}
    </StyledBookingDetailWrapper>
  );
}

export default JobDetail;
