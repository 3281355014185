import { PARAMS_REQUEST_DETAIL } from './create-request';

/* =========== ENUM ============ */
export enum CARD_TYPE {
  VISA = 'visa',
  MASTER_CARD = 'master-card',
  USA_EXPRESS = 'america-express',
}

export enum PAYMENT_MODAL_TYPE {
  PAY_NOW = 'paynow',
  CARD = 'card',
  ADD_NEW_CARD = 'ADD_NEW_CARD',
}

/* =========== INTERFACE ============ */
export interface CREDIT_BALANCE_RESPONSE {
  parent_id: string;
  balance: number;
  is_trial: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}

export interface CARD_LIST_RESPONSE {
  id: string;
  last4: string;
  brand: CARD_TYPE;
}

export interface SEARCH_FEE_PAYLOAD extends PARAMS_REQUEST_DETAIL {
  payment_method_id?: string; // for card + stripe
  payment_method_type: PAYMENT_MODAL_TYPE;
  // transaction_type: 'AC Search Fee';
}

export interface BUY_PACKAGE_PAYLOAD {
  payment_method_id?: string; // for card + stripe
  payment_method_type: PAYMENT_MODAL_TYPE;
  package_id: string;
}

export interface TRIAL_JOB_PAYLOAD {
  payment_method_id?: string; // for card + stripe
  payment_method_type: PAYMENT_MODAL_TYPE;
  job_id: string;
}

export interface PAYMENT_RESPONSE {
  id: string;
  amount: number;
  currency: string;
  status: string;
  client_secret: string;
  next_action: {
    paynow_display_qr_code: PAY_NOW_QRCODE;
    type: string;
  };
}

export interface PAY_NOW_QRCODE {
  data: string;
  hosted_instructions_url: string;
  image_url_png: string;
  image_url_svg: string;
}

export interface ATTACH_PAYMENT_METHOD_PAYLOAD {
  payment_method_id: string;
}

export interface WEB_HOOK_PAYMENT_RESPONSE {
  amount: string;
  parent_id: string;
  payment_intent_id: string;
}
