import { GlobalLoading } from 'components';
import { TOKEN_KEY } from 'constants/global';
import { useConnectWebSocket } from 'hooks';
import moment from 'moment-timezone';
import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configAppRoutes from 'routers';
import { useAppSelector } from 'store/hook';
import { ThemeProvider } from 'theme';
import { getCookies } from 'utils';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';

moment.tz.setDefault('Asia/Singapore');

const App = () => {
  const routes = useRoutes(configAppRoutes);
  const { globalLoading } = useAppSelector((state) => state.global);
  const { loadingAuth } = useAppSelector((state) => state.auth);
  const token = getCookies(TOKEN_KEY.ACCESS_TOKEN);

  useConnectWebSocket({
    token: token,
  });

  return (
    <Suspense fallback={<GlobalLoading open />}>
      <ThemeProvider>
        {!loadingAuth && routes}
        <GlobalLoading open={globalLoading} />
        <ToastContainer />
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
