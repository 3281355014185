import { Stack, Typography } from '@mui/material';
import { SITTER_TYPE } from 'models/sitter';
import { theme } from 'theme';
import { StyledBoxContent } from './styles';

interface Props {
  sitter: SITTER_TYPE;
}

function SectionAboutMe({ sitter }: Props) {
  return (
    <Stack spacing={1}>
      <Typography variant="Web_Title_22">About me</Typography>
      <StyledBoxContent>
        <Typography
          variant="Web_paragraph"
          color={theme.palette.text.secondary}
        >
          {sitter?.sitter.bio}
        </Typography>
      </StyledBoxContent>
    </Stack>
  );
}

export default SectionAboutMe;
