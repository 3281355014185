import { Typography } from '@mui/material';
import { TOTAL_TIME_EXPIRED_QRCODE } from 'constants/global';
import { QRCodeCanvas } from 'qrcode.react';
import { useEffect, useState } from 'react';

interface PaymentPayNowProps {
  valueQrCode: string;
}

const PaymentPayNow = ({ valueQrCode }: PaymentPayNowProps) => {
  const [countDown, setCountDown] = useState(TOTAL_TIME_EXPIRED_QRCODE);

  useEffect(() => {
    setCountDown(TOTAL_TIME_EXPIRED_QRCODE);
    const interval = setInterval(() => {
      setCountDown((pre) => {
        if (pre <= 1) {
          clearInterval(interval);
          return 0;
        }
        return pre - 1;
      });
    }, 1_000);
    return () => clearInterval(interval);
  }, []);

  const formatCountdown = (seconds: number) => {
    if (seconds === 0) {
      return 'QR Code is expired';
    }

    const convertToMinutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedMinutes = String(convertToMinutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <>
      <QRCodeCanvas
        value={valueQrCode}
        style={{ width: '210px', height: '220px' }}
      />
      <Typography className="countdown">
        {formatCountdown(countDown)}
      </Typography>
    </>
  );
};

export default PaymentPayNow;
