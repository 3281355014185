import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { NotifyService } from 'config';
import { PayloadActionData, RESPONSE_TYPE } from 'models';
import {
  ADD_CHILDREN_REQUEST_TYPE,
  CHILDREN_LIST_RESPONSE,
} from 'models/parent/children';
import { call, put, takeLatest } from 'redux-saga/effects';
import { parentApi } from 'services/parent';
import { setGlobalLoading } from 'store/reducers/global';
import { updateChildrenList } from 'store/reducers/parent';
import {
  ADD_CHILDREN_REQUEST,
  DELETE_CHILDREN_REQUEST,
  GET_CHILDREN_LIST_REQUEST,
} from 'store/reducers/parent/actionTypes';

function* getChildrenListRequest() {
  try {
    const response: AxiosResponse<RESPONSE_TYPE<CHILDREN_LIST_RESPONSE[]>> =
      yield call(parentApi.getChildrenList);
    yield put(updateChildrenList(response.data.data));
  } catch (error) {
    NotifyService.error(error);
  }
}

function* addChildrenRequest(
  action: PayloadAction<PayloadActionData<ADD_CHILDREN_REQUEST_TYPE>>,
) {
  try {
    yield put(setGlobalLoading(true));
    yield call(parentApi.addChildren, action.payload.data);
    action.payload.onSuccess?.();
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* deleteChildrenRequest(
  action: PayloadAction<PayloadActionData<{ id: string }>>,
) {
  try {
    yield put(setGlobalLoading(true));
    yield call(parentApi.deleteChildren, action.payload.data.id);
    action.payload.onSuccess?.();
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* childrenSaga() {
  yield takeLatest(GET_CHILDREN_LIST_REQUEST, getChildrenListRequest);
  yield takeLatest(ADD_CHILDREN_REQUEST, addChildrenRequest);
  yield takeLatest(DELETE_CHILDREN_REQUEST, deleteChildrenRequest);
}
export default childrenSaga;
