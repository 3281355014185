import { Button, Stack, Typography } from '@mui/material';
import { HugeIcon } from 'assets';
import ModalUI from 'components/common/ModalUI';
import PopupConfirm from 'components/popup/PopupConfirm';
import { useState } from 'react';
import { theme } from 'theme';

interface AcceptExtensionReductionModalProps {
  typeSitChange?: 'extension' | 'reduction';
  _onClose: () => void;
}

function AcceptExtensionReductionModal({
  typeSitChange = 'extension',
  _onClose,
}: AcceptExtensionReductionModalProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const isExtension = typeSitChange === 'extension';

  const onConfirm = () => {
    console.log('onConfirm');
  };

  return (
    <ModalUI
      open
      onClose={_onClose}
      maxW={500}
      sx={{
        '& .MuiBox-root': { pt: 2 },
      }}
    >
      {isOpen && (
        <PopupConfirm
          onClose={() => {
            setIsOpen(false);
            _onClose();
          }}
          title="Booking Updated"
          content={
            <Typography variant="Web_Label_14">
              Your booking has been successfully updated.
              <br /> Credits will be deducted accordingly from your plan.
            </Typography>
          }
          textButton="Back to Bookings"
        />
      )}

      <Stack>
        <Stack alignItems={'center'} pb={5}>
          <Typography fontSize={24} fontWeight={700}>
            Sit Change
          </Typography>
          <Typography variant="Web_Label_18" sx={{ opacity: 0.5 }}>
            {isExtension
              ? 'Extension of sit duration'
              : 'Reduction of sit duration'}
          </Typography>
        </Stack>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Stack spacing={1.5}>
            <Typography variant="Small_Profile_16">Current timing</Typography>
            <Stack spacing={1}>
              <Typography variant="Small_Body_16">
                29 Aug 2024 (12pm)
              </Typography>
              <Typography variant="Small_Body_16">29 Aug 2024 (9am)</Typography>
              <Typography variant="Web_Label_16">9 hours</Typography>
            </Stack>
          </Stack>
          <Stack spacing={1.5}>
            <Typography variant="Small_Profile_16" color="primary">
              New Timing
            </Typography>
            <Stack spacing={1}>
              <Typography variant="Small_Body_16">
                29 Aug 2024 (12pm)
              </Typography>
              <Typography variant="Small_Body_16">29 Aug 2024 (9am)</Typography>
              <Typography variant="Web_Label_16">9 hours</Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={1} direction={'row'} pt={4.5}>
          <HugeIcon
            sx={{
              color: isExtension
                ? theme.palette.primary.main
                : theme.palette.secondary.main,
              fontSize: 16,
            }}
          />
          <Typography
            variant="Mobile_Caption_Medium"
            color={isExtension ? 'primary' : 'secondary'}
          >
            Note: The sit hour has been extended, an additional charges of&nbsp;
            <Typography variant="Mobile_Caption_Medium" component="span">
              $XX
            </Typography>
            &nbsp;will be deducated from your credit upon confirmation.
          </Typography>
        </Stack>
        <Stack direction={'row'} pt={3} spacing={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={_onClose}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onConfirm}
          >
            {isExtension ? 'Confirm & Pay' : 'Confirm'}
          </Button>
        </Stack>
      </Stack>
    </ModalUI>
  );
}

export default AcceptExtensionReductionModal;
