import styled from '@emotion/styled';
import { DialogContent, DialogTitle, Stack } from '@mui/material';
import { theme } from 'theme';

export const StyledDialogTitle = styled(DialogTitle)`
  padding: 0px;
`;

export const DialogTitleText = styled(Stack)`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: ${theme.palette.primary.main};
`;

export const DialogSubTitleText = styled(Stack)`
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #132f41;
`;

export const StyledDialogContent = styled(DialogContent)`
  max-height: 500px;
  margin: 0px;
  padding: 16px 32px !important;
  border-radius: 5px;
  border: 1px solid ${theme.palette.grey4.light};
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${theme.palette.primary.main};
  }
  & p {
    line-height: 30px;
    font-size: 17px;
    font-weight: 500;
    color: #6b6b6b;
    letter-spacing: 0.2px;
  }
`;
