import { Stack } from '@mui/material'
import { StyledLogoIcon } from 'components/common/styles/auth'
import { Link } from 'react-router-dom'
import { APP_ROUTES } from 'routers/routes'

export const LogoAuth = () => {
  return (
    <Stack alignItems="center" mb={6}>
      <Link to={APP_ROUTES.AUTH.LOGIN.to}>
        <StyledLogoIcon />
      </Link>
    </Stack>
  )
}

export default LogoAuth
