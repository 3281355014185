import styled from '@emotion/styled';
import { Stack, Typography } from '@mui/material';
import { theme } from 'theme';

export const BookingCreditContainer = styled(Stack)`
  padding: 16px 24px;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid ${theme.palette.light.quaternary};
`;

export const StyledTitleWrap = styled(Stack)`
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

export const StyledDetailWrap = styled(Stack)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const StyledDetailText = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  & span {
    font-weight: 600;
  }
`;
