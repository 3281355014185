import { Avatar, Divider, Grid, Stack, Typography } from '@mui/material';
import { APP_IMG } from 'assets';
import SitterItem from 'components/common/SitterItem';
import { DATE_TIME_FORMAT, DEFAULT_FORMAT_DATE } from 'constants/date';
import { formatDate } from 'helpers';
import {
  AC_BASE_REQUEST_SITTER,
  OVERVIEW_CHILDREN_ITEM,
  OVERVIEW_RESPONSE,
} from 'models/overview';
import { Fragment, useEffect, useState } from 'react';
import { overviewApi } from 'services/overview';
import { theme } from 'theme';
import { pluralString } from 'utils';
import { daysOfWeekOptions } from '../createRequest/helpers';
import { DATA_SAMPLE, REQUEST_STATUS, requestStatusColor } from './constant';
import { StyledRequestItem, StyledSittersOverview } from './styled';

const RequestOverview = ({ data }: { data: OVERVIEW_RESPONSE }) => {
  const pets = Object.keys(data.pets).filter(
    (key) => data.pets[key as keyof typeof data.pets] === true,
  );

  const dayOfWeekSelected = data.days_of_week
    .map((apiDay) => daysOfWeekOptions.find((option) => option.id === apiDay))
    .filter(Boolean);

  console.log(dayOfWeekSelected);

  return (
    <StyledRequestItem spacing={2}>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: 'start', md: 'space-between' }}
        spacing={{ xs: 4, md: 0 }}
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 1, md: 0.5 }}
        >
          <Typography variant="Web_Label_18">Expected Due Date:</Typography>
          <Typography variant="Web_Title_18" color="primary">
            {formatDate(data.start_datetime, {
              format: DEFAULT_FORMAT_DATE,
            })}
          </Typography>
        </Stack>
        <Typography
          variant="Web_Label_18"
          sx={{
            textTransform: 'capitalize',
          }}
          color={
            requestStatusColor[data.status as keyof typeof requestStatusColor]
          }
        >
          {data.status}
        </Typography>
      </Stack>
      <Divider />
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={{ xs: 4, md: 14 }}
      >
        <Stack spacing={{ xs: 0.5 }}>
          <Typography variant="Web_Label_16" color={theme.palette.grey3.dark}>
            End Date
          </Typography>
          <Typography variant="Small_Body_16">
            {formatDate(data.end_datetime, {
              format: DEFAULT_FORMAT_DATE,
            })}
          </Typography>
        </Stack>
        <Stack spacing={{ xs: 0.5 }}>
          <Typography variant="Web_Label_16" color={theme.palette.grey3.dark}>
            Estimated No. of Hours per week
          </Typography>
          <Typography variant="Small_Body_16">
            {pluralString('hour', 50)}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        className="child_panel"
        direction={{ xs: 'column', md: 'row' }}
        spacing={4}
      >
        {data.children.map((children: OVERVIEW_CHILDREN_ITEM) => (
          <Stack
            direction="row"
            key={children.id}
            spacing={1}
            alignItems="center"
          >
            <Avatar sx={{ width: 44, height: 44, bgcolor: 'primary.main' }}>
              {children.full_name.charAt(0)}
            </Avatar>
            <Stack spacing={0.25}>
              <Typography variant="Title_Menu_14">
                {children.full_name}
              </Typography>
              <Typography variant="Small_Body_12">
                {pluralString('year', children.age_range)} old
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
      <Stack spacing={{ xs: 0.5 }}>
        <Typography variant="Web_Label_16" color={theme.palette.grey3.dark}>
          Address
        </Typography>
        <Typography variant="Small_Body_12">
          {`${data.address.street}, #${data.address.unit_number}-${data.address.block_number} ${data.address.country} ${data.address.postal_code}`}
        </Typography>
      </Stack>
      <Stack spacing={{ xs: 0.5 }}>
        <Typography variant="Web_Label_16" color={theme.palette.grey3.dark}>
          Dates & Timings
        </Typography>
        <Grid>
          {dayOfWeekSelected.map((day, index) => (
            <Typography variant="Small_Body_12" key={day.id}>
              {day.fullName}
              {index + 1 < dayOfWeekSelected.length && ', '}
            </Typography>
          ))}
        </Grid>
        <Typography variant="Small_Body_12">
          {formatDate(data.start_datetime, {
            format: DATE_TIME_FORMAT.HOUR_ONLY,
          })}
          &nbsp;to&nbsp;
          {formatDate(data.end_datetime, {
            format: DATE_TIME_FORMAT.HOUR_ONLY,
          })}
        </Typography>
      </Stack>
      <Stack spacing={{ xs: 0.5 }}>
        <Typography variant="Web_Label_16" color={theme.palette.grey3.dark}>
          Other Requirements
        </Typography>
        <Typography variant="Small_Body_12">
          {data.special_requirements}
        </Typography>
      </Stack>
      <Stack spacing={{ xs: 0.5 }}>
        <Typography variant="Web_Label_16" color={theme.palette.grey3.dark}>
          Pets
        </Typography>
        <Grid>
          {pets.map((pet, index) => {
            return (
              <Typography variant="Small_Body_12" key={index}>
                {pet.charAt(0).toUpperCase() + pet.slice(1)}
                {index + 1 < pets.length && ', '}
              </Typography>
            );
          })}
        </Grid>
      </Stack>
      <Divider />
      <Stack color={theme.palette.grey3.dark} direction="row" spacing={0.5}>
        <Typography variant="Small_Body_16">Created on:</Typography>
        <Typography variant="Small_Body_16">
          {formatDate(data.createdAt, {
            format: DEFAULT_FORMAT_DATE,
          })}
        </Typography>
      </Stack>
    </StyledRequestItem>
  );
};

const SittersOverView = ({
  data,
  status,
}: {
  data: AC_BASE_REQUEST_SITTER[];
  status: string;
}) => {
  return (
    <StyledSittersOverview spacing={2}>
      <Typography variant="Web_Label_18" sx={{ opacity: '50%' }}>
        {status === REQUEST_STATUS.accepted
          ? 'Sitter Accepted'
          : 'Sitter Matched'}
      </Typography>
      <Stack spacing={1.5}>
        {data.length > 0 ? (
          data.map((sitter: AC_BASE_REQUEST_SITTER) => (
            <SitterItem key={sitter.id} sitter={sitter} />
          ))
        ) : (
          <Fragment>
            <Grid item xs={12} pb={5}>
              <Stack
                justifyContent="center"
                alignItems="center"
                position="relative"
              >
                <img src={APP_IMG.backgroundEmpty} alt="bg_empty" />
                <Typography
                  position="absolute"
                  bottom={10}
                  sx={{ opacity: 0.5 }}
                  width="326px"
                  textAlign="center"
                  variant="Web_Label_14"
                >
                  We will <b>pair you with compatible Nannies</b>, check back
                  again to view the nannies.
                </Typography>
              </Stack>
            </Grid>
          </Fragment>
        )}
      </Stack>
    </StyledSittersOverview>
  );
};

const Overview = () => {
  const dataRequest = DATA_SAMPLE;
  const [dataOverview, setDataOverview] = useState<OVERVIEW_RESPONSE>();

  const fetchData = async () => {
    const response = await overviewApi.getDataOverview();
    setDataOverview(response.data.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!dataOverview) return;

  return (
    <Stack spacing={2}>
      <Stack spacing={0.5} mb={1}>
        <Typography variant="Web_Title_22">Overview</Typography>
        <Typography variant="Web_Label_18">Overview of your request</Typography>
      </Stack>
      <RequestOverview data={dataOverview} />
      <SittersOverView
        data={dataOverview.ac_base_request_sitters}
        status={dataRequest.status}
      />
    </Stack>
  );
};

export default Overview;
