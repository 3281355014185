import { Stack, Typography } from '@mui/material';
import { PaymentComponent } from 'components';
import PopupConfirm from 'components/popup/PopupConfirm';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getCurrentBalanceCreditAction } from 'store/reducers/credits/actionTypes';
import { setIsOpenPaymentSuccess } from 'store/reducers/payments';
import {
  payForNormalJobAction,
  payForTrialJobAction,
} from 'store/reducers/payments/actionTypes';
import { formatPrice } from 'utils';

const PaymentJobs = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { parent } = useAppSelector((state) => state.auth);
  const { dataPaymentJob, isOpenPaymentSuccess } = useAppSelector(
    (state) => state.payment,
  );
  const { currentBalance } = useAppSelector((state) => state.credits);

  const handleBackAction = () => {
    navigate(-1);
  };

  const onSuccessPayment = (isMethodPayNow: boolean) => {
    if (!isMethodPayNow) {
      dispatch(setIsOpenPaymentSuccess(true));
    }
  };

  useEffect(() => {
    if (!dataPaymentJob.isTrial) {
      dispatch(
        getCurrentBalanceCreditAction({
          data: { parentId: parent?.parent_id },
        }),
      );
    }
    // eslint-disable-next-line
  }, [dataPaymentJob.isTrial]);

  return (
    <Stack spacing={8}>
      <Stack spacing={1} alignItems="center">
        <Typography variant="Web_Title_22">Payment</Typography>
        <Typography variant="Web_Label_18">Buy a Plan</Typography>
      </Stack>
      <React.Fragment>
        <PaymentComponent
          feeSummaryChildren={
            <Stack>
              <Typography variant="Components_Column_content" color={'#6d6d6d'}>
                Enhanced Plan
              </Typography>
              <Typography variant="Components_Column_content" color={'#6d6d6d'}>
                {formatPrice(dataPaymentJob.total || 100)}
              </Typography>
            </Stack>
          }
          creditBalance={!dataPaymentJob.isTrial ? currentBalance : -1}
          totalAmount={dataPaymentJob.total || 100}
          morePayload={{
            job_id: dataPaymentJob.id,
          }}
          dispatchAction={
            !dataPaymentJob.isTrial
              ? payForNormalJobAction
              : payForTrialJobAction
          }
          handleBackAction={handleBackAction}
          onSuccessPayment={onSuccessPayment}
        />
      </React.Fragment>
      {isOpenPaymentSuccess && (
        <PopupConfirm
          typeIcon="check"
          title="Successful Payment"
          content={
            <Typography variant="Web_Label_14">
              Your payment has been successfully made
            </Typography>
          }
          textButton="Back to Bookings"
          onSubmit={handleBackAction}
          onClose={() => {
            dispatch(setIsOpenPaymentSuccess(false));
          }}
        />
      )}
    </Stack>
  );
};

export default PaymentJobs;
