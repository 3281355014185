import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface GlobalType {
  globalLoading: boolean;
  loadingBox: boolean;
}

const initialState: GlobalType = {
  globalLoading: false,
  loadingBox: false,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setGlobalLoading(state: GlobalType, action: PayloadAction<boolean>) {
      state.globalLoading = action.payload;
    },
    setLoadingBox(state: GlobalType, action: PayloadAction<boolean>) {
      state.loadingBox = action.payload;
    },
  },
});

export const { setGlobalLoading, setLoadingBox } = globalSlice.actions;

export default globalSlice.reducer;
