import { combineReducers } from 'redux';
import authReducer from './auth';
import creditsReducer from './credits';
import globalReducer from './global';
import jobReducer from './job';
import overviewReducer from './overview';
import parentReducer from './parent';
import paymentReducer from './payments';
import trialRequestReducer from './trial-request';

export const rootReducer = combineReducers({
  auth: authReducer,
  global: globalReducer,
  parent: parentReducer,
  payment: paymentReducer,
  jobs: jobReducer,
  credits: creditsReducer,
  trialRequest: trialRequestReducer,
  overview: overviewReducer,
});
