import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useQueryString() {
  const { search } = useLocation();
  return useMemo(
    () => Object.fromEntries(new URLSearchParams(search).entries()),
    [search],
  );
}

export default useQueryString;
