export const API = {
  PROFILE: {
    DEFAULT: 'users/profile',
    EDIT: 'parents/profile',
    UPLOAD_FILE: 'parents/upload',
    CHANGE_PASSWORD: 'users/change-password',
  },
  AUTH: {
    LOGIN: 'auth/login',
    REGISTER: 'auth/register',
    RESEND_OTP: 'auth/resend-code',
    VERIFY_PHONE: 'auth/verify-account',
    FORGOT_PASSWORD: 'auth/forgot-password',
    CHECK_CODE: 'auth/check-code',
    RESET_PASSWORD: 'auth/reset-password',
    CHOOSE_ROLE: 'auth/choose-role',
    REFRESH_TOKEN: 'auth/refresh-token',
  },
  PAYMENT: {
    GET_CREDIT_BALANCE: 'subscriptions/my-subscription',
    GET_ALL_CARDS: '/payment/payment-methods',
    CREATE_PAYMENT_METHOD: '',
    PAY_SEARCH_FEE: 'payment/pay-search-fee',
    ATTACH_PAYMENT_METHOD: 'payment/payment-methods/attach',
    FEE_SUMMARY: 'payment/ac-search-fee',
    BUY_PACKAGE: 'payment/purchase-package',
    TRIAL_JOB: 'payment/pay-trial-job',
    NORMAL_JOB: 'payment/pay-job',
  },
  CREDITS: {
    GET_BALANCE: (id: string) => `subscription-purchases/balance/${id}`,
    GET_PACKAGES: 'packages',
    SUBSCRIPTION_PURCHASE: 'subscription-purchases',
  },
  STATIC_DATA: {
    NATIONALITY: 'staticdata/nationality',
    ETHNICITY: 'staticdata/ethnicity',
  },
  OVERVIEW: {
    GET_OVERVIEW: 'ac-base-requests/my-request',
    UPDATE_STATUS_SITTER: (id: string) => `ac-base-request-sitters/${id}`,
  },
  SITTER: {
    DETAIL: 'srequests/sitters',
    REVIEWS: 'reviews',
  },
  BOOKING: {
    DEFAULT: 'parent/booking',
    CREATE: 'parent/booking/create-booking',
    SUMMARY: 'parent/booking/booking-summary',
    CANCEL: 'parent/booking/cancel-booking',
    CREATE_TESTIMONIAL: 'nanny/testimonial/create-testimonial',
    LIST: 'ac-jobs',
    DETAIL: (id: string) => `ac-jobs/${id}`,
  },
  PARENT: {
    GET_CHILDREN: 'parents/children',
    ADD_CHILDREN: 'parents/children',
    UPDATE_CHILDREN: (id: string) => `parents/children/${id}`,
    DELETE_CHILDREN: (id: string) => `parents/children/${id}`,
    GET_ADDRESS: 'parents/address',
    ADD_ADDRESS: 'parents/address',
    UPDATE_ADDRESS: (id: string) => `parents/address/${id}`,
    GET_REGION_LIST: 'regions',
    GET_ADD_BY_CODE: (postalCode: number) => `onemap/address/${postalCode}`,
    JOIN_SUBS: 'subscriptions',
  },
};
