import { Avatar, Button, Stack, Typography } from '@mui/material';
import {
  AC_BASE_REQUEST_SITTER,
  AC_REQUEST_SITTER_STATUS,
} from 'models/overview';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { useAppDispatch } from 'store/hook';
import {
  updateACRequestSitterStatus,
  updateSitterId,
} from 'store/reducers/overview';
import { theme } from 'theme';
import { pluralString, toCapitalize } from 'utils';

interface Props {
  sitter: AC_BASE_REQUEST_SITTER;
}

const SitterItem = ({ sitter }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <Stack
      key={sitter.id}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" alignItems="center" spacing={1.25}>
        {sitter.sitter.profile_picture ? (
          <img
            src={sitter.sitter.profile_picture}
            alt="sitter profile"
            style={{ width: 50, height: 50, borderRadius: '50%' }}
          />
        ) : (
          <Avatar sx={{ width: 50, height: 50 }} />
        )}

        <Stack spacing={0.5}>
          <Typography
            sx={{ fontWeight: 600 }}
            variant="Web_Label_16"
            color={
              sitter.status === AC_REQUEST_SITTER_STATUS.REJECTED
                ? 'rgba(183, 183, 183, 1)'
                : theme.palette.primary.main
            }
          >
            {toCapitalize(sitter.sitter.User.first_name) +
              ' ' +
              toCapitalize(sitter.sitter.User.last_name)}
          </Typography>
          <Typography
            display={{ xs: 'none', md: 'block' }}
            variant="Small_Body_12"
            color="rgba(142, 142, 147, 1)"
          >
            Total Exp on App: {pluralString('hour', sitter.sitter.hours_worked)}
          </Typography>
        </Stack>
      </Stack>

      <Stack
        direction={{ xs: 'column-reverse', md: 'row' }}
        spacing={{ xs: 0.5, md: 2 }}
        alignItems="center"
      >
        <Typography variant="Web_Label_14" color={theme.palette.red.light}>
          {toCapitalize(sitter.status)}
        </Typography>
        <Button
          variant="contained"
          disabled={sitter.status === AC_REQUEST_SITTER_STATUS.REJECTED}
          onClick={() => {
            dispatch(updateSitterId(sitter.id));
            dispatch(
              updateACRequestSitterStatus(
                sitter.status as AC_REQUEST_SITTER_STATUS,
              ),
            );
            navigate(APP_ROUTES.SITTER_DETAIL.to(sitter.sitter.User.id));
          }}
          sx={{
            height: '35px',
            fontSize: '12px',
            paddingY: '6px',
            lineHeight: '1px',
          }}
        >
          View
        </Button>
      </Stack>
    </Stack>
  );
};

export default SitterItem;
