import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { RESPONSE_TYPE } from 'models';
import { REVIEW_TYPE } from 'models/sitter';
import ReviewItem from './ReviewItem';

interface Props {
  reviews: RESPONSE_TYPE<REVIEW_TYPE>;
}

const mockItems = Array.from(Array(4).keys());

function SectionReviews({ reviews }: Props) {
  return (
    <Stack spacing={1}>
      <Typography variant="Web_Title_22">Reviews</Typography>
      <Typography
        variant="Web_Title_22"
        color="rgba(157, 157, 157, 1)"
        fontWeight={400}
      >
        {`${reviews?.data.sitter.review_count} Reviews, ${reviews?.data.sitter.repeat_count} Repeat Reviews`}
      </Typography>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {reviews
            ? reviews?.data.rows.map((review) => (
                <Grid key={review.id} item xs={12} md={6}>
                  <ReviewItem review={review} />
                </Grid>
              ))
            : mockItems.map((_, idx) => (
                <Grid key={idx} item xs={12} md={6}>
                  <Skeleton
                    sx={{ height: '198px', borderRadius: '8px' }}
                    variant="rectangular"
                  />
                </Grid>
              ))}
        </Grid>
      </Box>
    </Stack>
  );
}

export default SectionReviews;
