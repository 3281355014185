import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CARD_LIST_RESPONSE } from 'models/payments';

export interface PaymentType {
  isOpenPaymentModal: boolean;
  isOpenBuyCredits: boolean;
  paymentMethodId: string;
  paymentIntentId: string;
  isOpenPaymentSuccess: boolean;
  dataPaymentJob: {
    total: number;
    id: string;
    isTrial: boolean;
  };
  listCards: CARD_LIST_RESPONSE[];
}

const initialState: PaymentType = {
  paymentMethodId: '',
  paymentIntentId: '',
  isOpenPaymentModal: false,
  isOpenBuyCredits: false,
  isOpenPaymentSuccess: false,
  dataPaymentJob: {
    total: 0,
    id: '',
    isTrial: false,
  },
  listCards: [],
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setIsOpenPaymentModal(state: PaymentType, action: PayloadAction<boolean>) {
      state.isOpenPaymentModal = action.payload;
    },
    setIsOpenBuyCredits(state: PaymentType, action: PayloadAction<boolean>) {
      state.isOpenBuyCredits = action.payload;
    },
    setIsOpenPaymentSuccess(
      state: PaymentType,
      action: PayloadAction<boolean>,
    ) {
      state.isOpenPaymentSuccess = action.payload;
    },
    updatePaymentMethodId(state: PaymentType, action: PayloadAction<string>) {
      state.paymentMethodId = action.payload;
    },
    updatePaymentIntentId(state: PaymentType, action: PayloadAction<string>) {
      state.paymentIntentId = action.payload;
    },
    updateDataPaymentJob(
      state: PaymentType,
      action: PayloadAction<{
        total: number;
        id: string;
        isTrial: boolean;
      }>,
    ) {
      state.dataPaymentJob = action.payload;
    },
    updateListCards(
      state: PaymentType,
      action: PayloadAction<CARD_LIST_RESPONSE[]>,
    ) {
      state.listCards = action.payload;
    },
  },
});

// Actions
export const {
  setIsOpenPaymentModal,
  updatePaymentMethodId,
  updatePaymentIntentId,
  setIsOpenBuyCredits,
  setIsOpenPaymentSuccess,
  updateDataPaymentJob,
  updateListCards,
} = paymentSlice.actions;

export default paymentSlice.reducer;
