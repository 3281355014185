import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { NotifyService } from 'config';
import { PayloadActionData, RESPONSE_TYPE } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { paymentApi } from 'services/payment';
import { setGlobalLoading } from 'store/reducers/global';
import { updatePaymentIntentId } from 'store/reducers/payments';
import { PAY_FOR_BUY_PACKAGE_REQUEST } from 'store/reducers/payments/actionTypes';
import {
  BUY_PACKAGE_PAYLOAD,
  PAYMENT_RESPONSE,
} from './../../../models/payments';

function* payForBuyPackageRequest(
  action: PayloadAction<PayloadActionData<BUY_PACKAGE_PAYLOAD>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<PAYMENT_RESPONSE>> = yield call(
      paymentApi.buyPackage,
      action.payload.data,
    );
    action.payload.onSuccess?.(response.data.data);
    yield put(updatePaymentIntentId(response.data.data.id));
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* payPackageSaga() {
  yield takeLatest(PAY_FOR_BUY_PACKAGE_REQUEST, payForBuyPackageRequest);
}
export default payPackageSaga;
