import { SvgIcon, SvgIconProps } from '@mui/material';

export function HelpIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle opacity="0.08" cx="12" cy="12" r="10" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6.75C10.7574 6.75 9.75 7.75736 9.75 9C9.75 9.41421 9.41421 9.75 9 9.75C8.58579 9.75 8.25 9.41421 8.25 9C8.25 6.92893 9.92893 5.25 12 5.25C14.0711 5.25 15.75 6.92893 15.75 9C15.75 10.6424 14.6946 12.0363 13.2273 12.5444C13.0697 12.5989 12.9366 12.6873 12.8517 12.7813C12.7708 12.8708 12.75 12.9441 12.75 13V14C12.75 14.4142 12.4142 14.75 12 14.75C11.5858 14.75 11.25 14.4142 11.25 14V13C11.25 11.9975 12.0578 11.3619 12.7365 11.1269C13.6185 10.8216 14.25 9.98356 14.25 9C14.25 7.75736 13.2426 6.75 12 6.75Z"
        fill="black"
      />
      <path
        d="M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z"
        fill="black"
      />
    </SvgIcon>
  );
}
