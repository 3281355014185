import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { NotifyService } from 'config';
import {
  GET_LIST_JOB_PARAMS,
  JOB_LIST_RESPONSE,
  PayloadActionData,
  RESPONSE_TYPE,
} from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { jobApi } from 'services';
import { setGlobalLoading } from 'store/reducers/global';
import { updateJobDetail, updateJobList } from 'store/reducers/job';
import {
  GET_JOB_DETAIL_REQUEST,
  GET_JOB_LIST_REQUEST,
} from 'store/reducers/job/actionTypes';

function* getBookingListRequest(
  action: PayloadAction<PayloadActionData<GET_LIST_JOB_PARAMS>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<JOB_LIST_RESPONSE>> =
      yield call(jobApi.getList, action.payload.data);
    action.payload.onSuccess?.();
    yield put(updateJobList(response.data.data));
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* getBookingDetailRequest(
  action: PayloadAction<PayloadActionData<{ id: string }>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<any>> = yield call(
      jobApi.getDetail,
      action.payload.data.id,
    );
    action.payload.onSuccess?.();
    yield put(updateJobDetail(response.data.data));
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* bookingSaga() {
  yield takeLatest(GET_JOB_LIST_REQUEST, getBookingListRequest);
  yield takeLatest(GET_JOB_DETAIL_REQUEST, getBookingDetailRequest);
}
export default bookingSaga;
