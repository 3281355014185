import styled from '@emotion/styled';
import { Stack } from '@mui/material';

export const JobListWrapper = styled(Stack)`
  overflow-y: auto;
  overflow-x: hidden;
  align-items: flex-start;
  & .infinite-scroll-component {
    @media (max-width: 1024px) {
      overflow: hidden !important;
    }
  }
  & > div {
    width: 100%;
  }
`;
