import { APIInstance } from 'config/configApi';
import { API } from 'constants/apiEndpoints';
import { RESPONSE_TYPE, USER_PROFILE } from 'models';
import {
  PARENT_CHANGE_PASSWORD_REQUEST,
  PARENT_UPDATE_PROFILE_REQUEST,
  PARENT_UPLOAD_FILE_RESPONSE,
} from 'models/profile';

export const profileApi = {
  getProfile: () => {
    return APIInstance.get<RESPONSE_TYPE<USER_PROFILE>>(API.PROFILE.DEFAULT);
  },
  updateProfile: (data: PARENT_UPDATE_PROFILE_REQUEST) => {
    return APIInstance.put<RESPONSE_TYPE<any>>(API.PROFILE.EDIT, data);
  },
  uploadPicture: (data: any) => {
    return APIInstance.post<RESPONSE_TYPE<PARENT_UPLOAD_FILE_RESPONSE>>(
      API.PROFILE.UPLOAD_FILE,
      data,
    );
  },
  changePassword: (data: PARENT_CHANGE_PASSWORD_REQUEST) => {
    return APIInstance.put<RESPONSE_TYPE<any>>(
      API.PROFILE.CHANGE_PASSWORD,
      data,
    );
  },
};
