export const DATA_SAMPLE: any = {
  id: 1,
  start_date: '02/08/2023',
  service_period: '6 months',
  est: 60,
  children: [
    {
      id: 1,
      name: 'Jennifer',
      age: '2 years old',
    },
    {
      id: 2,
      name: 'Liam',
      age: '3 months old',
    },
  ],
  address: 'Blk 35 Mandalay Road, #01-01 Singapore 134567',
  timings: 'Weekday 7am to 7pm',
  other:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi',
  pets: 'no',
  created_at: '13/06/2022',
  sitters: [
    {
      id: '6662d57a4c724a0051224b26',
      name: 'Sitter Name',
      status: 'pending',
      exp: '100 Hours',
    },
    {
      id: '6662d57a4c724a0051224b27',
      name: 'Sitter Name',
      status: 'rejected',
      exp: '91.5 Hours',
    },
  ],
  status: 'pending',
};

export enum REQUEST_STATUS {
  pending = 'pending',
  accepted = 'accepted',
}

export const requestStatusColor = {
  [REQUEST_STATUS.accepted]: 'secondary.main',
  [REQUEST_STATUS.pending]: '#B6B6B6',
};
