import { APIInstance } from 'config/configApi';
import { API } from 'constants/apiEndpoints';
import {
  CHOOSE_ROLE_DATA,
  CHOOSE_ROLE_RESPONSE,
  LOGIN_REQUEST,
  LOGIN_RESPONSE,
  PARENT_FORGOT_PASSWORD_DATA,
  PARENT_RESET_PASSWORD_DATA,
  PARENT_VERIFY_PHONE_RESPONSE,
  REGISTER_REQUEST,
  REGISTER_RESPONSE,
  RESPONSE_TYPE,
} from 'models';

export const authApi = {
  login: (params: LOGIN_REQUEST) => {
    return APIInstance.post<RESPONSE_TYPE<LOGIN_RESPONSE>>(
      API.AUTH.LOGIN,
      params,
    );
  },
  register: (params: REGISTER_REQUEST) => {
    return APIInstance.post<RESPONSE_TYPE<REGISTER_RESPONSE>>(
      API.AUTH.REGISTER,
      params,
    );
  },
  verifyPhone: (data: { phone_number: string; code: string }) => {
    return APIInstance.post<RESPONSE_TYPE<PARENT_VERIFY_PHONE_RESPONSE>>(
      API.AUTH.VERIFY_PHONE,
      data,
    );
  },
  chooseRole: (data: CHOOSE_ROLE_DATA) => {
    return APIInstance.post<RESPONSE_TYPE<CHOOSE_ROLE_RESPONSE>>(
      API.AUTH.CHOOSE_ROLE,
      data,
    );
  },
  resendOTP: (phone: string) => {
    return APIInstance.post<RESPONSE_TYPE<any>>(API.AUTH.RESEND_OTP, {
      phone_number: phone,
    });
  },
  forgotPassword: (data: PARENT_FORGOT_PASSWORD_DATA) => {
    return APIInstance.post<RESPONSE_TYPE<any>>(API.AUTH.FORGOT_PASSWORD, data);
  },
  resetPassword: (data: PARENT_RESET_PASSWORD_DATA) => {
    return APIInstance.put<RESPONSE_TYPE<any>>(API.AUTH.RESET_PASSWORD, data);
  },
};
