import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';

export const cookies = new Cookies();

export const getCookies = (key: string): string => {
  return cookies.get(key);
};

export const setCookies = (key: string, data: unknown) => {
  cookies.set(key, data);
};

export const removeCookies = (key: string) => {
  cookies.remove(key, { path: '/' });
};

export const setCookiesExpired = (key: string, value: any, exp?: number) => {
  const timeNow = new Date().getTime();
  const shortExp = timeNow + 1 * 24 * 3600 * 1000; // 1 day
  const expires = exp ? new Date(exp * 1000) : new Date(shortExp);
  cookies.set(key, value, {
    path: '/',
    expires,
  });
};

const getTokenExpires = (token: string) => {
  const { exp } = jwtDecode<{ exp: number }>(token);
  const timeNow = new Date().getTime();
  const shortExp = timeNow + 5 * 3600 * 1000; // 5 hours

  const expires = exp ? new Date(exp * 1000) : new Date(shortExp);
  return expires;
};

export const setJWTCookies = (key: string, token: string) => {
  cookies.set(key, token, {
    path: '/',
    expires: getTokenExpires(token),
  });
};
