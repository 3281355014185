import { yupResolver } from '@hookform/resolvers/yup';
import { InputAdornment, Stack, Typography } from '@mui/material';
import { InputField } from 'components';
import { ButtonSubmit, PageTitle } from 'components/common/styles/auth';
import { NotifyService } from 'config/notify';
import yup from 'config/yup.custom';
import { useDebounce } from 'hooks';
import queryString from 'query-string';
import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { authApi } from 'services';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setGlobalLoading } from 'store/reducers/global';
import LogoAuth from '../../components/LogoAuth';
import { ResendBox, ResendText, SentText } from './styles';

interface IPhoneVerifyForm {
  otp: string;
}

const RESEND_DURATION = 60;

const schema = yup
  .object({
    otp: yup.string().length(6, 'Incorrect OTP').required('OTP is required'),
  })
  .required();

interface PhoneVerifyProps {}

export const PhoneVerify = memo((_: PhoneVerifyProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const queryParams: {
    phone?: string;
  } = queryString.parse(location.search);

  const { globalLoading } = useAppSelector((state) => state.global);
  const [isSentOTP, setIsSentOTP] = useState(false);
  const [countDown, setCountDown] = useState(RESEND_DURATION);
  const [resetCountDown, setResetCountDown] = useState(0);

  const navigate = useNavigate();

  const { handleSubmit, control } = useForm<IPhoneVerifyForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setCountDown(RESEND_DURATION);
    const interval = setInterval(() => {
      setCountDown((pre) => {
        if (pre <= 1) {
          clearInterval(interval);
          return 0;
        }
        return pre - 1;
      });
    }, 1_000);
    return () => clearInterval(interval);
  }, [resetCountDown]);

  const onResend = useDebounce(async () => {
    if (countDown || globalLoading) return;
    setIsSentOTP(false);
    dispatch(setGlobalLoading(true));
    await authApi
      .resendOTP(queryParams.phone)
      .then(() => {
        setIsSentOTP(true);
        setResetCountDown((pre) => pre + 1);
        NotifyService.success('Success');
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => dispatch(setGlobalLoading(false)));
  }, 500);

  const onSubmit = useDebounce(async (data: IPhoneVerifyForm) => {
    dispatch(setGlobalLoading(true));
    await authApi
      .verifyPhone({ phone_number: queryParams.phone, code: data.otp })
      .then(() => {
        navigate(APP_ROUTES.AUTH.LOGIN.to);
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => dispatch(setGlobalLoading(false)));
  }, 500);

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
      <LogoAuth />
      <PageTitle variant="h1" mb={1}>
        Phone Verification
      </PageTitle>
      <Typography mb={2} variant="Web_Label_14" color="grey2.dark">
        OTP has been sent to {queryParams.phone}
      </Typography>
      <InputField
        numberOnly
        control={control}
        name="otp"
        placeholder="Enter OTP"
        endAdornment={
          <InputAdornment position="end">
            <ResendBox>
              <ResendText
                $ready={!countDown && !globalLoading}
                onClick={onResend}
              >
                {countDown ? `${countDown}s` : 'Resend'}
              </ResendText>
            </ResendBox>
          </InputAdornment>
        }
      />
      {isSentOTP && <SentText>OTP has been resent.</SentText>}
      <Stack
        mt={3}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <ButtonSubmit color="primary">Verify</ButtonSubmit>
      </Stack>
    </Stack>
  );
});

export default PhoneVerify;
