import styled from '@emotion/styled';
import { Button, Stack, Typography } from '@mui/material';
import { APP_IMG, ChevronLeftIcon } from 'assets';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { theme } from 'theme';

const StyledRequestSuccess = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  margin: 'auto',
  '& .title': {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '30px',
    color: theme.palette.text.secondary,
  },
  '& button': {
    marginTop: '20px !important',
  },
}));

const RequestSuccess = () => {
  const navigate = useNavigate();
  const onRedirectOverview = () => {
    navigate(APP_ROUTES.OVERVIEW.to);
  };

  return (
    <StyledRequestSuccess spacing={2}>
      <img src={APP_IMG.requestCreated} alt="request-created" />
      <Typography className="title">Request Created!</Typography>
      <Typography variant="Web_Label_16">
        Your search request has been created
      </Typography>
      <Button
        variant="contained"
        endIcon={<ChevronLeftIcon sx={{ fontSize: 30, pb: '2px' }} />}
        onClick={onRedirectOverview}
      >
        Request Overview
      </Button>
    </StyledRequestSuccess>
  );
};

export default RequestSuccess;
