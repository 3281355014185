import styled from '@emotion/styled';
import { InputBase, inputBaseClasses } from '@mui/material';
import { theme } from 'theme';

export const StyledInput = styled(InputBase)`
  width: 100%;
  height: 48px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid ${theme.palette.light.stroke};
  background: ${theme.palette.background.light};

  & .${inputBaseClasses.input} {
    padding-left: 15px;
    color: ${theme.palette.light.quaternary};
    pointer-events: none;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: ${theme.palette.black.dark};
    ::placeholder {
      color: ${theme.palette.light.quaternary};
    }
  }
  & svg {
    margin-right: 15px;
  }
`;
