import { Stack, styled } from '@mui/material';

export const StyledRequestItem = styled(Stack)`
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #959595;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);

  .child_panel {
    padding: 13px 22px;
    background-color: rgba(255, 121, 110, 0.04);
  }
`;

export const StyledSittersOverview = styled(Stack)`
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #959595;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
`;
