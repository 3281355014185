import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, Typography } from '@mui/material';
import { DatePickerField } from 'components';
import TimePickerField from 'components/form-control/TimePickerField';
import PopupConfirm from 'components/popup/PopupConfirm';
import yup from 'config/yup.custom';
import { SYS_MESS } from 'constants/systemMessage';
import { EDIT_JOB_FORM } from 'models';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getJobDetailAction } from 'store/reducers/job/actionTypes';
import { EditBookingContainer, FormEditBookingContainer } from './styles';

const schema = yup
  .object()
  .shape({
    startDate: yup.mixed().required(SYS_MESS.ERROR.START_DATE_REQUIRED),
    endDate: yup.mixed().required(SYS_MESS.ERROR.END_DATE_REQUIRED),
    startTime: yup.mixed().required(SYS_MESS.ERROR.START_TIME_REQUIRED),
    endTime: yup.mixed().required(SYS_MESS.ERROR.END_TIME_REQUIRED),
  })
  .required();

function EditJob() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { jobDetail } = useAppSelector((state) => state.jobs);

  const [isOpenInsufficientBalance, setIsOpenInsufficientBalance] =
    useState<boolean>(true);

  const form = useForm<EDIT_JOB_FORM>({
    resolver: yupResolver(schema),
    defaultValues: {
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
    },
  });

  const { handleSubmit, control, watch, setValue } = form;

  const onSubmit = (values: EDIT_JOB_FORM) => {
    console.log(values, 'onSubmit');
  };

  const getMinTime = useMemo(() => {
    const originalHours = watch('startTime')?.getHours();
    const originalMins = watch('startTime')?.getMinutes();

    const newHours = (originalHours + 2) % 24;
    return new Date(0, 0, 0, newHours, originalMins);
    // eslint-disable-next-line
  }, [watch('startTime'), jobDetail]);

  const getMaxTime = useMemo(() => {
    if (watch('startDate')?.isSame(watch('endDate'))) {
      return new Date(0, 0, 0, 23, 59);
    }
    return new Date(0, 0, 0, 0, 0);
    // eslint-disable-next-line
  }, [watch('startTime'), watch('endDate'), jobDetail]);

  useEffect(() => {
    dispatch(getJobDetailAction({ data: { id } }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (jobDetail) {
      setValue('startDate', moment(jobDetail.start_datetime));
      setValue('endDate', moment(jobDetail.end_datetime));
      setValue('startTime', new Date(jobDetail.start_datetime));
      setValue('endTime', new Date(jobDetail.end_datetime));
    }
    // eslint-disable-next-line
  }, [jobDetail]);

  // useEffect(() => {
  //   setValue('endTime', null);
  // }, [watch('startTime')]);

  console.log(watch('startTime'));
  console.log(watch('endTime'));
  console.log(getMinTime, 'getMinTime');

  return (
    <EditBookingContainer spacing={4}>
      {isOpenInsufficientBalance && (
        <PopupConfirm
          typeIcon="help"
          title="Insufficient hour-balance"
          content={
            <Typography variant="Web_Label_14">
              You don’t have enough hour-balance
              <br /> to extend this session.
            </Typography>
          }
          textButton="Top-up"
          textButton2="Back"
          onClose={() => {
            setIsOpenInsufficientBalance(false);
          }}
        />
      )}
      <Stack>
        <Typography fontSize={22} fontWeight={700} color="black.dark">
          Edit Booking
        </Typography>
        <Typography fontSize={18} fontWeight={400} color="text.secondary">
          Extend or shorten duration
        </Typography>
      </Stack>
      <FormEditBookingContainer onSubmit={handleSubmit(onSubmit)}>
        <Stack flexDirection={'row'} gap={2}>
          <DatePickerField
            control={control}
            name={'startDate'}
            iconColor="primary"
            placeholder="Expected Start Date"
            label="Start Date"
            minDate={moment()}
          />
          <TimePickerField
            control={control}
            name={'startTime'}
            placeholderText="Expected Start Time"
            label="Start Time"
          />
        </Stack>
        <Stack flexDirection={'row'} gap={2}>
          <DatePickerField
            control={control}
            name={'endDate'}
            iconColor="primary"
            placeholder="Expected End Date"
            label="End Date"
            minDate={watch('startDate')}
          />
          <TimePickerField
            control={control}
            name={'endTime'}
            placeholderText="End Time"
            label="End Time"
            minTime={getMinTime}
            maxTime={getMaxTime}
          />
        </Stack>
        <Button variant="contained" type="submit">
          Save
        </Button>
        <Button variant="text" onClick={() => navigate(-1)}>
          Back
        </Button>
      </FormEditBookingContainer>
    </EditBookingContainer>
  );
}

export default EditJob;
