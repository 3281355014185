import { Collapse, CollapseProps, Typography } from '@mui/material';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

interface InlineErrorProps {
  open: boolean;
  error:
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined
    | string;
  rootProps?: CollapseProps;
}

const InlineError = ({ open, error, rootProps = {} }: InlineErrorProps) => {
  return (
    <Collapse in={open} {...rootProps}>
      <Typography typography="errors" color="red.light">
        {typeof error === 'string' ? error : (error?.message as string)}
      </Typography>
    </Collapse>
  );
};

export default InlineError;
