import styled from '@emotion/styled';
import { FormControl, Radio, RadioGroup } from '@mui/material';
import { formControlLabelClasses } from '@mui/material/FormControlLabel';
import { radioClasses } from '@mui/material/Radio';
import { typographyClasses } from '@mui/material/Typography';
import { theme } from 'theme';

export const FormControlContainer = styled(FormControl)`
  .${formControlLabelClasses.root} {
  }
  margin: 0px;
  display: flex;
  gap: 10px;
  .${radioClasses.root} {
    padding: 0px;
    color: ${theme.palette.grey1.dark};
  }
  .${radioClasses.checked} {
    color: ${theme.palette.primary.main};
  }
  .${typographyClasses.root} {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    color: ${theme.palette.common.black};
  }
`;

export const RadioGroupContainer = styled(RadioGroup)`
  flex-direction: row;
  gap: 20px;
  & label {
    margin: 0px;
  }
`;

export const RadioIconWrap = styled(Radio)`
  & svg {
    width: 20px;
    height: 20px;
  }
`;
