import styled from '@emotion/styled';
import { Box, Stack, Typography } from '@mui/material';
import { theme } from 'theme';
import { transientOptions } from 'utils/transientOptions';

export const StyledPackWrapper = styled(Stack, transientOptions)<{
  $isHasTag: boolean;
}>`
  margin-top: 12px;
  position: relative;
  cursor: pointer;
  padding: 25px 20px 14px 20px;
  border-radius: 24px;
  height: 280px;
  width: 204px;
  align-items: center;
  justify-content: space-between;
  background: ${theme.palette.background.light};

  & p {
    color: ${({ $isHasTag }) =>
      $isHasTag ? theme.palette.primary.main : theme.palette.black.dark};
  }

  border: ${({ $isHasTag }) =>
    $isHasTag ? `1px solid ${theme.palette.primary.main}` : 'none'};
  background: ${({ $isHasTag }) =>
    $isHasTag ? theme.palette.light.shades8 : theme.palette.background.light};
`;

export const StyledBoxPopular = styled(Box)`
  padding: 3px;
  border-radius: 5px;
  background: ${theme.palette.primary.main};
  color: ${theme.palette.white.light};
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  position: absolute;
  top: 0px;
  left: 10px;
  text-transform: capitalize;
  z-index: 1;
`;

export const StyledPackNameWrapper = styled(Stack)`
  & .package-name {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-align: center;
    color: ${theme.palette.text.secondary};
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    word-break: break-work;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  & .package-description {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-align: center;
    color: ${theme.palette.light.quaternary};
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    word-break: break-work;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-transform: capitalize;
  }
`;

export const StyledPriceWrapper = styled(Stack)`
  justify-content: center;
  align-items: center;
  & .per-hour {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-align: center;
    color: ${theme.palette.light.tertiary} !important;
    opacity: 0.5;
  }
  & .total-hours {
    font-size: 30px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-align: center;
    display: flex;
  }
  & .text-house {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-align: center;
  }
`;

export const StyledDetailText = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  & span {
    font-weight: 600;
  }
`;

export const StyledBuyButtonWrapper = styled(Stack)`
  gap: 1;
  & button {
    height: 38px;
  }
  & p {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-align: center;
  }
`;
