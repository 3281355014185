import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { CheckCircleBold } from 'assets';
import { InputField, UploadAvatar } from 'components';
import { NotifyService } from 'config/notify';
import yup from 'config/yup.custom';
import { useDebounce } from 'hooks';
import { USER_TYPE } from 'models';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { profileApi } from 'services';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getUserProfileAction } from 'store/reducers/auth/actionTypes';
import { setGlobalLoading } from 'store/reducers/global';
import PopupChangePassword from '../../components/PopupChangePassword';
import { AlertStyled } from './styles';

interface IEditProfileForm {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  profilePicture: string;
}

const schema = yup
  .object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email().required('Email is required'),
    phoneNumber: yup.mixed().required('Phone number is required'),
  })
  .required();

export const EditProfile = () => {
  const dispatch = useAppDispatch();
  const { globalLoading } = useAppSelector((state) => state.global);
  const { parent } = useAppSelector((state) => state.auth);
  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);

  const { handleSubmit, control, reset, setValue, watch } =
    useForm<IEditProfileForm>({
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    reset({
      firstName: parent.first_name || '',
      lastName: parent.last_name || '',
      email: parent.email || '',
      phoneNumber: `${parent.country_code}${parent.phone_number}` || '',
      profilePicture: parent.profile_picture,
    });
  }, [parent, reset]);

  const onRefreshData = () => {
    dispatch(
      getUserProfileAction({
        data: {
          userType: USER_TYPE.PARENT,
        },
      }),
    );
  };

  const onSubmit = useDebounce(async (data: IEditProfileForm) => {
    if (globalLoading || !parent) return;
    dispatch(setGlobalLoading(true));
    await profileApi
      .updateProfile({})
      .then(() => {
        onRefreshData();
      })
      .catch((e) => {
        NotifyService.error(e);
      })
      .finally(() => {
        dispatch(setGlobalLoading(false));
      });
  }, 500);

  const onChangeProfilePicture = async (file: File) => {
    if (!parent) return;
    dispatch(setGlobalLoading(true));
    const formData = new FormData();
    formData.append('file', file);
    await profileApi
      .uploadPicture(formData)
      .then(({ data }) => {
        setValue('profilePicture', data?.data.url);
      })
      .catch((e) => {
        NotifyService.error(e);
      })
      .finally(() => {
        dispatch(setGlobalLoading(false));
      });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack
        mb={2.5}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="Web_Title_22">Edit Profile</Typography>
        <Stack
          display={{ xs: 'none', md: 'flex' }}
          direction="row"
          spacing={1.5}
        >
          <Button
            sx={{
              padding: '8.2px 16.4px',
            }}
            variant="outlined"
            onClick={() => setOpenChangePassword(true)}
          >
            Change Password
          </Button>
          <Button
            sx={{
              padding: '8.2px 16.4px',
            }}
            type="submit"
            variant="contained"
            endIcon={<CheckCircleBold sx={{ width: '24px', height: '24px' }} />}
          >
            Save Profile
          </Button>
        </Stack>
      </Stack>
      <Stack spacing={4}>
        <AlertStyled variant="outlined" severity="info">
          This profile syncs with your Aunty account, changes made here will
          also apply to Aunty app.
        </AlertStyled>
        <Stack spacing={4}>
          <UploadAvatar
            role={USER_TYPE.PARENT}
            onChange={(val) => onChangeProfilePicture(val)}
            value={watch('profilePicture')}
          />
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <InputField
                  control={control}
                  name="firstName"
                  label="First Name*"
                  placeholder="First Name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField
                  control={control}
                  name="lastName"
                  label="Last Name*"
                  placeholder="Last Name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField
                  disabled
                  control={control}
                  name="email"
                  label="Email"
                  placeholder="Email"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField
                  disabled
                  control={control}
                  name="phoneNumber"
                  label="Phone Number"
                  placeholder="Phone Number"
                />
              </Grid>
            </Grid>
          </Box>
          <Stack display={{ xs: 'flex', md: 'none' }} spacing={3}>
            <Button
              sx={{
                padding: '8.2px 16.4px',
              }}
              variant="contained"
              type="submit"
              onClick={() => {}}
            >
              Save Profile
            </Button>
            <Button
              sx={{
                padding: '8.2px 16.4px',
              }}
              variant="outlined"
              onClick={() => setOpenChangePassword(true)}
            >
              Change Password
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <PopupChangePassword
        openPop={openChangePassword}
        onClose={() => setOpenChangePassword(false)}
      />
    </Box>
  );
};

export default EditProfile;
