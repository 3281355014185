import { SvgIcon, SvgIconProps } from '@mui/material';

export function RadioUnCheckIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      sx={{ fill: 'none' }}
    >
      <circle
        cx="10"
        cy="10"
        r="9.5"
        stroke="currentColor"
        strokeOpacity="0.6"
      />
    </SvgIcon>
  );
}
