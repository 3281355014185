import { SvgIcon, SvgIconProps } from '@mui/material';

export function CheckedIconCircle(props: SvgIconProps) {
  return (
    <SvgIcon
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.928711" width="24" height="24" rx="12" fill="currentColor" />
      <path
        d="M6.92871 12L11.1714 16.2426L19.6566 7.75732"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default CheckedIconCircle;
