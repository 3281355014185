import { Stack, Typography } from '@mui/material';
import PackageCreditItem from 'components/common/PackageCreditItem';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getPackageAvailableAction } from 'store/reducers/credits/actionTypes';

const BuyCredits = () => {
  const dispatch = useAppDispatch();
  const { availablePackages } = useAppSelector((state) => state.credits);

  useEffect(() => {
    dispatch(getPackageAvailableAction());
  }, [dispatch]);

  return (
    <Stack spacing={8}>
      <Stack spacing={1} alignItems="center">
        <Typography variant="Web_Title_22">Buy Credits</Typography>
        <Typography variant="Web_Label_18">
          Select a Sitter Plan to purchase
        </Typography>
      </Stack>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
        sx={{ gap: 1 }}
      >
        {availablePackages?.records?.map((item) => (
          <PackageCreditItem key={item.id} data={item} />
        ))}
      </Stack>
    </Stack>
  );
};

export default BuyCredits;
