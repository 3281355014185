import { NotifyService } from 'config';
import { SYS_MESS } from 'constants/systemMessage';
import { CONNECTION_WSS_STATUS, EVENT_WSS } from 'models';
import { PURCHASED_PACKAGE_FROM } from 'models/credits';
import { WEB_HOOK_PAYMENT_RESPONSE } from 'models/payments';
import { useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { io, Socket } from 'socket.io-client';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setIsOpenPaymentModal } from 'store/reducers/payments';
import { updateStepTrial } from 'store/reducers/trial-request';
import useQueryString from './useQueryString';

type IConnectWebSocketInput = {
  token: string;
};

type IConnectWebSocketOutput = {
  socketIO?: Socket;
  onReconnectConnection?: () => void;
  onCloseConnection?: () => void;
  onDisconnectConnection?: () => void;
};

/**
 * Custom hook to handle connect wss and integration with socketIO.
 * Custom hook will return some functions & data real time.
 */
export const useConnectWebSocket = ({
  token,
}: IConnectWebSocketInput): IConnectWebSocketOutput => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const query = useQueryString();
  const { paymentIntentId } = useAppSelector((state) => state.payment);

  // "undefined" means the URL will be computed from the `window.location` object
  const URL =
    process.env.NODE_ENV === 'production'
      ? undefined
      : process.env.REACT_APP_SOCKET_URL;

  const socketIO = useMemo(() => {
    if (!token) return null;

    const socket = io(URL, {
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
      // transports: ['websocket'],
      autoConnect: false,
      reconnection: false,
    });

    return socket;
  }, [URL, token]);

  const isConnectedRef = useRef<boolean>(false);

  const _window: any = window;
  _window['socketIO'] = socketIO;

  const onReconnectConnection = () => {
    socketIO.connect();
  };

  const onCloseConnection = () => {
    console.log('onCloseConnection');
    socketIO.close();
  };

  const onDisconnectConnection = () => {
    console.log('onDisconnectConnection');
    socketIO.disconnect();
  };

  useEffect(() => {
    if (!token || !socketIO || isConnectedRef.current) return;

    const connectSocket = () => {
      if (socketIO && !socketIO.connected) {
        socketIO.connect();
        isConnectedRef.current = true;
      }
    };

    const handleConnect = () => {
      console.log(`Connected status:${CONNECTION_WSS_STATUS.CONNECTED}`);
      isConnectedRef.current = true;
    };

    const handleDisconnect = () => {
      console.log(`Connection status: ${CONNECTION_WSS_STATUS.DISCONNECT}`);
      onReconnectConnection();
    };

    const handleClose = () => {
      console.log(`Connection status: ${CONNECTION_WSS_STATUS.CLOSE}`);
      isConnectedRef.current = false;
      onCloseConnection();
    };

    const handleError = (err: Error) => {
      console.error(
        `Connection status: ${CONNECTION_WSS_STATUS.CONNECT_ERROR}`,
        err,
      );
      isConnectedRef.current = false;
      onDisconnectConnection();
    };

    const handleListenEvent = (
      data: WEB_HOOK_PAYMENT_RESPONSE,
      event: EVENT_WSS,
    ) => {
      if (!data.payment_intent_id.includes(paymentIntentId)) return;
      NotifyService.success(SYS_MESS.SUCCESS.PAYMENT);
      dispatch(setIsOpenPaymentModal(false));

      switch (event) {
        case EVENT_WSS.PAYMENT_SEARCH_FEE_SUCCESS:
          dispatch(updateStepTrial(4));
          break;
        case EVENT_WSS.PAYMENT_TRIAL_JOB_SUCCESS:
          navigate(-1);
          break;
        case EVENT_WSS.PACKAGE_PURCHASE_SUCCESS:
          if (query.type === PURCHASED_PACKAGE_FROM.JOB) {
            navigate(-1);
          } else {
            navigate(APP_ROUTES.CREDITS.OVERVIEW.to);
          }
          break;
        default:
          break;
      }
    };

    socketIO.on(CONNECTION_WSS_STATUS.CONNECTED, handleConnect);
    socketIO.on(CONNECTION_WSS_STATUS.CONNECT_ERROR, handleError);
    socketIO.on(CONNECTION_WSS_STATUS.CONNECTED, handleDisconnect);
    socketIO.on(CONNECTION_WSS_STATUS.CLOSE, handleClose);

    socketIO.on(
      EVENT_WSS.PAYMENT_SEARCH_FEE_SUCCESS,
      (data: WEB_HOOK_PAYMENT_RESPONSE) =>
        handleListenEvent(data, EVENT_WSS.PAYMENT_SEARCH_FEE_SUCCESS),
    );
    socketIO.on(
      EVENT_WSS.PAYMENT_TRIAL_JOB_SUCCESS,
      (data: WEB_HOOK_PAYMENT_RESPONSE) =>
        handleListenEvent(data, EVENT_WSS.PAYMENT_TRIAL_JOB_SUCCESS),
    );
    socketIO.on(
      EVENT_WSS.PACKAGE_PURCHASE_SUCCESS,
      (data: WEB_HOOK_PAYMENT_RESPONSE) =>
        handleListenEvent(data, EVENT_WSS.PACKAGE_PURCHASE_SUCCESS),
    );

    connectSocket();

    return () => {
      socketIO.off(CONNECTION_WSS_STATUS.CONNECTED, handleConnect);
      socketIO.off(CONNECTION_WSS_STATUS.CONNECT_ERROR, handleError);
      socketIO.off(CONNECTION_WSS_STATUS.CONNECTED, handleDisconnect);
      socketIO.off(CONNECTION_WSS_STATUS.CLOSE, handleClose);

      isConnectedRef.current = false;
    };

    // eslint-disable-next-line
  }, []);

  // Keep this line to tracking connection
  console.log(socketIO);

  return {
    socketIO,
    onReconnectConnection,
    onDisconnectConnection,
    onCloseConnection,
  };
};
