import { SvgIcon, SvgIconProps } from '@mui/material';

export function RadioCheckIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle opacity="0.2" cx="10.5" cy="10.5" r="10" stroke="black" />
      <circle cx="10.5" cy="10.5" r="6.5" fill="currentColor" />
    </SvgIcon>
  );
}
