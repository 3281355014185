import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, FormHelperText, Stack, Typography } from '@mui/material';
import { InputField } from 'components';
import {
  ButtonBottom,
  ButtonSubmit,
  ForgotLink,
  PageTitle,
} from 'components/common/styles/auth';
import { getDataError, NotifyService } from 'config/notify';
import yup from 'config/yup.custom';
import { useAuth } from 'contexts/auth';
import { useDebounce } from 'hooks';
import { CHOOSE_ROLE_RESPONSE } from 'models';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { authApi } from 'services';
import { paymentApi } from 'services/payment';
import { useAppDispatch } from 'store/hook';
import { setGlobalLoading } from 'store/reducers/global';
import LogoAuth from '../../components/LogoAuth';

interface ILoginForm {
  email: string;
  password: string;
}

const schema = yup
  .object({
    email: yup.string().required('Email is required'),
    password: yup.string().required('Password is required'),
  })
  .required();

export const Login = () => {
  const { login, checkIsTrial } = useAuth();
  const dispatch = useAppDispatch();

  const [incorrect, setIncorrect] = useState(false);

  const { handleSubmit, control, watch } = useForm<ILoginForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const subscription = watch(() => {
      if (incorrect) setIncorrect(false);
    });
    return () => subscription.unsubscribe();
  }, [watch, incorrect]);

  const getCreditBalance = async (dataChooseRole: CHOOSE_ROLE_RESPONSE) => {
    await paymentApi
      .getCreditBalance(dataChooseRole.access_token)
      .then(({ data: { data } }) => {
        checkIsTrial(data.is_trial);
      })
      .catch((e) => {
        const res = getDataError<{ error: string }>(e);
        if (res?.error === 'Subscription not found') {
          checkIsTrial(true);
        }
      })
      .finally(() => {
        login(dataChooseRole);
      });
  };

  const onChooseRole = async (accessToken: string) => {
    await authApi
      .chooseRole({
        token: accessToken,
        role: 'parent',
      })
      .then(({ data: { data } }) => {
        NotifyService.success('Success');
        getCreditBalance(data);
      })
      .catch((e) => NotifyService.error(e));
  };

  const onSubmit = useDebounce(async (data: ILoginForm) => {
    dispatch(setGlobalLoading(true));
    await authApi
      .login({
        email: data.email,
        password: data.password,
      })
      .then(({ data: { data } }) => {
        onChooseRole(data.token);
      })
      .catch((e) => {
        const res = getDataError<{ error: string }>(e);
        if (res?.error === 'Unauthorized') {
          setIncorrect(true);
        } else NotifyService.error(e);
      })
      .finally(() => dispatch(setGlobalLoading(false)));
  }, 500);
  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
      <LogoAuth />
      <PageTitle variant="h1" mb={1.625}>
        Parent Login
      </PageTitle>
      <Stack spacing={2}>
        <InputField
          control={control}
          name="email"
          placeholder="Email or phone number"
        />
        <Stack>
          <InputField
            control={control}
            name="password"
            placeholder="Password"
            type="password"
          />
          {incorrect && (
            <FormHelperText error sx={{ textAlign: 'right' }}>
              Incorrect phone or password combination
            </FormHelperText>
          )}
        </Stack>
        <Stack
          mt={2}
          mb={4}
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <ForgotLink to={APP_ROUTES.AUTH.FORGOT_PASSWORD.to}>
            Forgot Password?
          </ForgotLink>
          <ButtonSubmit color="primary">Login</ButtonSubmit>
        </Stack>
        <Divider sx={{ mb: 3 }} />
        <Typography variant="Web_Label_14" sx={{ opacity: 0.5, mb: 1.25 }}>
          Don’t have an account?
        </Typography>
        <ButtonBottom component={NavLink} to={APP_ROUTES.AUTH.SIGN_UP.to}>
          Sign up as Parent
        </ButtonBottom>
      </Stack>
    </Stack>
  );
};

export default Login;
