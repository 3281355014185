import styled from '@emotion/styled';
import { Button, Stack, Typography } from '@mui/material';
import { theme } from 'theme';

export const StyledOverviewCredits = styled(Stack)`
  background: ${theme.palette.light.shades4};
  border-radius: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px;
`;

export const StyledCurrentBalanceTitle = styled(Typography)`
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
`;

export const StyledCurrentBalanceValue = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: ${theme.palette.primary.main};
  & span {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    opacity: 0.5;
    color: ${theme.palette.black.dark};
  }
`;

export const BuyCreditsButton = styled(Button)`
  width: 155px;
  height: 48px;
`;
