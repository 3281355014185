import {
  JOB_CHILDREN_ITEM,
  JOB_PARENT_INFORMATION,
  JOB_SITTER_INFORMATION,
} from './job';
import { ADDRESS_LIST_RESPONSE } from './parent/address';

/* =========== ENUM ============ */
export enum AC_REQUEST_SITTER_STATUS {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export interface OVERVIEW_RESPONSE {
  id: string;
  parent_id: string;
  number: number | null;
  start_datetime: string;
  end_datetime: string;
  days_of_week: number[];
  address_id: string;
  children_ids: string[];
  pets: {
    cats: boolean;
    dogs: boolean;
  };
  special_requirements: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  parent: JOB_PARENT_INFORMATION;
  ac_base_request_sitters: AC_BASE_REQUEST_SITTER[];
  address: ADDRESS_LIST_RESPONSE;
  children: OVERVIEW_CHILDREN_ITEM[];
}

export interface AC_BASE_REQUEST_SITTER {
  id: string;
  status: string;
  sitter: OVERVIEW_SITTER_INFORMATION;
}

export interface OVERVIEW_CHILDREN_ITEM extends JOB_CHILDREN_ITEM {}

export interface OVERVIEW_ADDRESS_ITEM extends ADDRESS_LIST_RESPONSE {}

export interface OVERVIEW_SITTER_INFORMATION extends JOB_SITTER_INFORMATION {
  hours_worked: number;
  profile_picture: string;
}
