import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JOB_DETAIL_RESPONSE, JOB_LIST_RESPONSE } from 'models';

export interface JobType {
  jobList: JOB_LIST_RESPONSE;
  jobDetail: JOB_DETAIL_RESPONSE;
  jobId: string;
}

const initialState: JobType = {
  jobList: {
    metadata: {
      page: 0,
      limit: 0,
      page_count: 0,
      total_pages: 0,
      total_count: 0,
    },
    records: [],
  },
  jobDetail: null,
  jobId: '',
};

const jobSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    updateJobList(state: JobType, action: PayloadAction<JOB_LIST_RESPONSE>) {
      if (action.payload.metadata.page <= 1) {
        state.jobList = action.payload;
      } else {
        state.jobList = action.payload;
        state.jobList = {
          ...state.jobList,
          ...action.payload,
          records: state.jobList.records.concat(action.payload.records),
        };
      }
    },
    updateJobDetail(
      state: JobType,
      action: PayloadAction<JOB_DETAIL_RESPONSE>,
    ) {
      state.jobDetail = action.payload;
    },
    updateJobId(state: JobType, action: PayloadAction<string>) {
      state.jobId = action.payload;
    },
  },
});

// Actions
export const { updateJobList, updateJobDetail, updateJobId } = jobSlice.actions;

export default jobSlice.reducer;
