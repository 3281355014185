import {
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { CloseCircle } from 'assets';
import PaymentPayNow from 'components/common/Payments/components/PaymentPayNow';
import PaymentStripe from 'components/common/Payments/components/PaymentStripe';
import { PAYMENT_MODAL_TYPE } from 'models/payments';
import { theme } from 'theme';

const PaymentModalWrapper = styled(Dialog)(() => ({
  '& .MuiDialogTitle-root': {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .title-modal': {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '36px',
      paddingTop: 1,
    },
  },
  '& .MuiDialogContent-root': {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0 0 0 !important',
    '& .countdown': {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      marginTop: 15,
      marginBottom: 15,
      textAlign: 'center',
    },
  },
}));

interface PaymentModalProps {
  onClose: () => void;
  methodType: PAYMENT_MODAL_TYPE;
  valueQrCode: string;
}

const PaymentModal = ({
  methodType,
  onClose,
  valueQrCode,
  ...rest
}: PaymentModalProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isMethodPayNow = methodType === PAYMENT_MODAL_TYPE.PAY_NOW;

  const _onCloseModal = () => {
    onClose();
  };

  return (
    <PaymentModalWrapper
      open
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          padding: 4,
          width: isMethodPayNow ? '280px' : '400px',
          height: isMethodPayNow ? '420px' : 'auto',
        },
      }}
      onClose={_onCloseModal}
      {...rest}
    >
      <DialogTitle padding={0}>
        <Typography className="title-modal">
          {isMethodPayNow ? 'PayNow' : 'Add Debit/Credit Card'}
        </Typography>
        <CloseCircle
          onClick={_onCloseModal}
          sx={{ color: theme.palette.grey1.dark }}
          cursor={'pointer'}
        />
      </DialogTitle>
      <DialogContent>
        {isMethodPayNow ? (
          <PaymentPayNow valueQrCode={valueQrCode} />
        ) : (
          <PaymentStripe />
        )}
      </DialogContent>
    </PaymentModalWrapper>
  );
};

export default PaymentModal;
