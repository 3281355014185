import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { theme } from 'theme';

export const StyledReviewItem = styled(Stack)`
  width: 100%;
  border-radius: 5px;
  border: 1px solid #959595;
  background: ${theme.palette.white.light};
  padding: 24px;
`;
