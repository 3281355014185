import styled from '@emotion/styled';
import { Box, Grid } from '@mui/material';
import { theme } from 'theme';

export const Content = styled(Box)`
  flex: 1;
  min-width: 0;
  min-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  > * {
    flex: 1;
    max-width: 335px;
    min-width: 0;
    min-height: 0;
  }
`;

export const RightBox = styled(Grid)`
  background: ${theme.palette.primary_shades[20]};
  align-items: center;
  justify-content: center;
  padding: 32px 20px 0;
  img {
    overflow: hidden;
    background: ${theme.palette.white.light};
  }
`;
