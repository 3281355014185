import { setToken } from 'config/configApi';
import { TOKEN_KEY } from 'constants/global';
import { useDebounce } from 'hooks';
import { CHOOSE_ROLE_RESPONSE, USER_TYPE } from 'models';
import { useEffect, useMemo, useRef, useState } from 'react';
import { APP_ROUTES } from 'routers/routes';
import { useAppDispatch } from 'store/hook';
import { setLoadingAuth } from 'store/reducers/auth';
import { getUserProfileAction } from 'store/reducers/auth/actionTypes';
import { CookieChangeOptions } from 'universal-cookie';
import { isUndefined } from 'utils';
import { cookies, getCookies, removeCookies, setCookies } from 'utils/cookies';
import createContext from 'utils/createContext';

interface AuthContextValues {
  isLoggedIn: boolean;
  authRedirect: React.MutableRefObject<boolean>;
  login: (data: CHOOSE_ROLE_RESPONSE, callback?: () => void) => void;
  logout: () => void;
  isTrial: boolean;
  checkIsTrial: (isTrial: boolean) => void;
}

const [Provider, useAuth] = createContext<AuthContextValues>({
  name: 'auth',
});

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const dispatch = useAppDispatch();

  const authRedirect = useRef(true);
  const loginCallback = useRef<() => void>();
  const [isTrial, setIsTrial] = useState<boolean>(false);

  const [accessToken, setAccessToken] = useState(
    getCookies(TOKEN_KEY.ACCESS_TOKEN),
  );
  const [refreshToken, setRefreshToken] = useState(
    getCookies(TOKEN_KEY.REFRESH_TOKEN),
  );

  const checkIsTokenAvailable = () => {
    if (!isUndefined(accessToken) && !isUndefined(refreshToken)) return true;
    return false;
  };

  const isLoggedIn = useMemo(() => {
    return checkIsTokenAvailable();
    // eslint-disable-next-line
  }, [accessToken, refreshToken]);

  useEffect(() => {
    if (!isLoggedIn) return;
    loginCallback.current?.();
    loginCallback.current = undefined;
  }, [isLoggedIn]);

  useEffect(() => {
    const pairKey = [TOKEN_KEY.ACCESS_TOKEN, TOKEN_KEY.REFRESH_TOKEN];
    function onChangeCookie(data: CookieChangeOptions) {
      if (pairKey.includes(data.name)) {
        checkIsTokenAvailable();
      }
    }
    cookies.addChangeListener(onChangeCookie);
    return () => {
      cookies.removeChangeListener(onChangeCookie);
    };
    // eslint-disable-next-line
  }, []);

  const checkIsTrial = (isTrial: boolean) => {
    setIsTrial(isTrial);
  };

  const login = (data: CHOOSE_ROLE_RESPONSE, callback?: () => void) => {
    if (callback) {
      authRedirect.current = false;
      loginCallback.current = () => {
        callback();
        authRedirect.current = true;
      };
    }
    setAccessToken(data.access_token);
    setRefreshToken(data.refresh_token);
    setToken(data.access_token);
    setCookies(TOKEN_KEY.ACCESS_TOKEN, data.access_token);
    setCookies(TOKEN_KEY.REFRESH_TOKEN, data.refresh_token);
  };

  const logout = () => {
    removeCookies(TOKEN_KEY.ACCESS_TOKEN);
    removeCookies(TOKEN_KEY.REFRESH_TOKEN);
    cookies.remove(TOKEN_KEY.ACCESS_TOKEN);
    cookies.remove(TOKEN_KEY.REFRESH_TOKEN);
    const _window: any = window;
    _window.location = APP_ROUTES.AUTH.LOGIN.to;
  };

  const fetchAuth = useDebounce(() => {
    !loginCallback.current && dispatch(setLoadingAuth(true));

    if (!isLoggedIn) {
      !loginCallback.current && dispatch(setLoadingAuth(false));
    } else {
      dispatch(
        getUserProfileAction({
          data: {
            userType: USER_TYPE.PARENT,
          },
          onFailed: () => {
            logout();
          },
          onFinally: () =>
            !loginCallback.current && dispatch(setLoadingAuth(false)),
        }),
      );
    }
  }, 50);

  useEffect(() => {
    fetchAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const value = useMemo(() => {
    return {
      isLoggedIn,
      authRedirect,
      isTrial,
      login,
      logout,
      checkIsTrial,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return <Provider value={value}>{children}</Provider>;
};

export { AuthProvider, useAuth };
