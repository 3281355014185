import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import { InputField } from "components";
import { ButtonSubmit, PageTitle } from "components/common/styles/auth";
import { NotifyService } from "config/notify";
import yup from "config/yup.custom";
import { TOKEN_KEY } from "constants/global";
import { useDebounce } from "hooks";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routers/routes";
import { authApi } from "services";
import { useAppDispatch } from "store/hook";
import { setGlobalLoading } from "store/reducers/global";
import { getCookies, removeCookies } from "utils";
import LogoAuth from "../../components/LogoAuth";

interface IResetPasswordForm {
  newPassword: string;
  confirmPassword: string;
}

const schema = yup
  .object({
    newPassword: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword")], "Password does not match")
      .required("Confirm password is required"),
  })
  .required();

function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accessToken = getCookies(TOKEN_KEY.FORGOT_PASSWORD.ACCESS_TOKEN);
  const { handleSubmit, control } = useForm<IResetPasswordForm>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = useDebounce(async (data: IResetPasswordForm) => {
    dispatch(setGlobalLoading(true));
    await authApi
      .resetPassword({ new_password: data.newPassword, token: accessToken })
      .then(() => {
        removeCookies(TOKEN_KEY.FORGOT_PASSWORD.ACCESS_TOKEN);
        NotifyService.success("Success");
        navigate(APP_ROUTES.AUTH.LOGIN.to);
      })
      .catch((e) => {
        NotifyService.error(e);
      })
      .finally(() => dispatch(setGlobalLoading(false)));
  }, 500);

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
      <LogoAuth />
      <PageTitle variant="h1" mb={1}>
        Reset Password
      </PageTitle>
      <Typography mb={2} variant="Web_Label_14" color="grey2.dark">
        Reset your password below
      </Typography>
      <Stack spacing={2}>
        <InputField
          control={control}
          name="newPassword"
          placeholder="Password"
          type="password"
        />
        <InputField
          control={control}
          name="confirmPassword"
          placeholder="Confirm password"
          type="password"
        />
      </Stack>
      <Stack
        mt={2}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <ButtonSubmit color="primary">Reset</ButtonSubmit>
      </Stack>
    </Stack>
  );
}

export default ResetPassword;
