import styled from '@emotion/styled';
import { List, Stack, Typography } from '@mui/material';
import { theme } from 'theme';

export const FormContainer = styled('form')`
  display: flex;
  max-width: 400px;
`;

export const StyledTitle = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
`;

export const SelectRegionField = styled(Stack)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 8px;
  border: 1px solid ${theme.palette.light.stroke};
  height: 48px;
  background: ${theme.palette.background.light};
  & > .MuiTypography-root::placeholder {
    color: ${theme.palette.light.placeholder};
  }
`;

export const StyledRegionList = styled(List)`
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  max-height: 405px;
  overflow: auto;
  & .MuiFormControlLabel-root {
    width: calc(50% - 20px);
    cursor: pointer;
  }
`;
