import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { theme } from 'theme';

export const StyledButtonWrapper = styled(Stack)`
  flex-direction: row;
  width: 100%;
  gap: 8px;
  justify-content: center;
  & button {
    width: auto;
  }
  & .btn-2 {
    background-color: ${theme.palette.secondary.main};
  }
`;
