import { BoxProps, TypographyProps } from '@mui/material'
import { ImageUploadIcon } from 'assets'
import { NotifyService } from 'config/notify'
import { useIsMountedRef } from 'hooks'
import { USER_TYPE } from 'models'
import { memo, useCallback, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { theme } from 'theme'
import { fData } from 'utils'
import { BoxAvatar, EmptyAvatar, Root, Title } from './styles'

const PHOTO_SIZE = 3145728 // bytes
const FILE_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']

const ROLE_COLOR = {
  [USER_TYPE.NANNY]: {
    bg: theme.palette.secondary_shades[8],
    color: theme.palette.secondary.main,
  },
  [USER_TYPE.PARENT]: {
    bg: theme.palette.primary_shades[8],
    color: theme.palette.primary.main,
  },
}

interface UploadAvatarProps {
  role: USER_TYPE
  value?: string // url
  rootProps?: BoxProps
  titleProps?: TypographyProps
  onChange?: (file: File) => void
}

export const UploadAvatar = memo(
  ({ role, value, rootProps, titleProps, onChange }: UploadAvatarProps) => {
    const isMountedRef = useIsMountedRef()

    const roleColor = useMemo(() => ROLE_COLOR[role], [role])

    const handleDrop = useCallback(
      async (acceptedFiles: File[]) => {
        let file = acceptedFiles[0]
        const checkSize = file.size < PHOTO_SIZE
        const checkType = FILE_FORMATS.includes(file.type)
        if (!checkSize) {
          NotifyService.error(`Images must be less than ${fData(PHOTO_SIZE)}`)
          return
        }
        if (!checkType) {
          NotifyService.error(
            'Unsupported format, please use image with formats: JPG, JPEG, PNG, or GIF.',
          )
          return
        }
        onChange && onChange(file)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isMountedRef, onChange],
    )

    const { open, getRootProps, getInputProps } = useDropzone({
      onDrop: handleDrop,
      multiple: false,
    })

    return (
      <Root {...rootProps}>
        <BoxAvatar {...getRootProps()}>
          <input {...getInputProps()} />
          {!!value && <img src={value} alt="profile" />}
          <EmptyAvatar sx={{ background: roleColor.bg }}>
            <ImageUploadIcon sx={{ color: roleColor.color }} />
          </EmptyAvatar>
        </BoxAvatar>
        <Title sx={{ color: roleColor.color }} {...titleProps} onClick={open}>
          {value ? 'Change profile photo' : 'Add profile photo'}
        </Title>
      </Root>
    )
  },
)

export default UploadAvatar
