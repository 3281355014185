import { SvgIcon, SvgIconProps } from '@mui/material';

export function HelpCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle opacity="0.4" cx="28" cy="28" r="27.5" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 16.3125C26.1015 16.3125 24.5625 17.8515 24.5625 19.75C24.5625 20.8891 23.6391 21.8125 22.5 21.8125C21.3609 21.8125 20.4375 20.8891 20.4375 19.75C20.4375 15.5733 23.8233 12.1875 28 12.1875C32.1767 12.1875 35.5625 15.5733 35.5625 19.75C35.5625 21.2524 35.1222 22.6583 34.363 23.8382C33.8875 24.5773 33.3315 25.2828 32.8188 25.92C32.725 26.0365 32.6329 26.1506 32.5423 26.2627C32.1169 26.7892 31.7265 27.2725 31.3656 27.7684C30.4758 28.9914 30.0625 29.9104 30.0625 30.75V32.125C30.0625 33.2641 29.1391 34.1875 28 34.1875C26.8609 34.1875 25.9375 33.2641 25.9375 32.125V30.75C25.9375 28.552 27.0166 26.7344 28.0301 25.3414C28.4584 24.7529 28.9277 24.1724 29.3544 23.6445C29.4399 23.5388 29.5236 23.4352 29.605 23.334C30.1143 22.7011 30.5478 22.1443 30.8941 21.6061C31.2379 21.0719 31.4375 20.4374 31.4375 19.75C31.4375 17.8515 29.8985 16.3125 28 16.3125Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 36.9375C29.1391 36.9375 30.0625 37.8609 30.0625 39V41.75C30.0625 42.8891 29.1391 43.8125 28 43.8125C26.8609 43.8125 25.9375 42.8891 25.9375 41.75V39C25.9375 37.8609 26.8609 36.9375 28 36.9375Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default HelpCircleIcon;
