import { Stack, Typography } from '@mui/material';
import { PaymentComponent } from 'components';
import { NotifyService } from 'config';
import { SYS_MESS } from 'constants/systemMessage';
import React, { useEffect, useState } from 'react';
import { paymentApi } from 'services/payment';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setIsOpenPaymentModal } from 'store/reducers/payments';
import { paySearchFeeAction } from 'store/reducers/payments/actionTypes';
import { updateStepTrial } from 'store/reducers/trial-request';
import { convertTimeToUTC, formatPrice, mergeDateTime } from 'utils';

function RequestPayment() {
  const dispatch = useAppDispatch();
  const { bodyCreateRequest } = useAppSelector((state) => state.trialRequest);
  const [searchFee, setSearchFee] = useState<number>(0);

  const getSearchFeeSummary = async () => {
    const response = await paymentApi.getSearchFeeSummary();
    setSearchFee(response.data.data);
  };

  useEffect(() => {
    getSearchFeeSummary();
    // eslint-disable-next-line
  }, []);

  const _dataSubmitRequestDetail = {
    start_datetime: convertTimeToUTC(
      mergeDateTime(
        bodyCreateRequest.requestDetail.startDate,
        bodyCreateRequest.requestDetail.startTime,
      ),
    ),
    end_datetime: convertTimeToUTC(
      mergeDateTime(
        bodyCreateRequest.requestDetail.endDate,
        bodyCreateRequest.requestDetail.endTime,
      ),
    ),
    days_of_week: bodyCreateRequest.requestDetail.dayOfWeeks.map(Number),
    address_id: bodyCreateRequest.requestDetail.address.id,
    children_ids: bodyCreateRequest.requestDetail.children.map(
      (item) => item.id,
    ),
    pets: {
      cats: bodyCreateRequest.requestDetail.pets.includes('Cats'),
      dogs: bodyCreateRequest.requestDetail.pets.includes('Dogs'),
    },
    special_requirements: bodyCreateRequest.requestDetail.specialRequirements,
  };

  const onSuccessPayment = (isPN: boolean) => {
    if (isPN) {
      dispatch(setIsOpenPaymentModal(true));
    } else {
      dispatch(updateStepTrial(4));
      NotifyService.success(SYS_MESS.SUCCESS.PAYMENT);
    }
  };

  return (
    <React.Fragment>
      <PaymentComponent
        feeSummaryChildren={
          <>
            <Stack direction={'row'}>
              <Typography variant="Components_Column_content" color={'#6d6d6d'}>
                Trial Fee
              </Typography>
              <Typography variant="Components_Column_content" color={'#6d6d6d'}>
                {formatPrice(searchFee)}
              </Typography>
            </Stack>
            <Stack direction={'row'}>
              <Typography variant="Components_Column_content" color={'#6d6d6d'}>
                Additional Child Surcharge
              </Typography>
              <Typography variant="Components_Column_content" color={'#6d6d6d'}>
                {formatPrice(0)}
              </Typography>
            </Stack>
          </>
        }
        totalAmount={searchFee}
        morePayload={_dataSubmitRequestDetail}
        handleBackAction={() => dispatch(updateStepTrial(2))}
        onSuccessPayment={onSuccessPayment}
        dispatchAction={paySearchFeeAction}
      />
    </React.Fragment>
  );
}

export default RequestPayment;
