import { Stack, Typography } from '@mui/material';
import SitterPanel from 'components/common/SitterPanel';
import { SITTER_TYPE } from 'models/sitter';

interface Props {
  sitter: SITTER_TYPE;
}

const SectionExperience = ({ sitter }: Props) => {
  const itemsKids = sitter?.checkLists.find(
    (item) => item.section_name === 'Experience with kids of age',
  );
  const itemsSpecial = sitter?.checkLists.find(
    (item) => item.section_name === 'Experience with Special Needs?',
  );

  return (
    <Stack spacing={1}>
      <Typography variant="Web_Title_22">Experience</Typography>
      <Stack spacing={{ xs: 1, md: 3 }} direction={{ xs: 'column', md: 'row' }}>
        <SitterPanel
          item={{ section_title: 'Kids of age', items: itemsKids?.items ?? [] }}
        />
        <SitterPanel
          item={{
            section_title: 'Special needs',
            items: itemsSpecial?.items ?? [],
          }}
        />
      </Stack>
    </Stack>
  );
};

export default SectionExperience;
