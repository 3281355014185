import { Button, Divider, Stack, Typography } from '@mui/material';
import { formatDate } from 'helpers';
import {
  ACTION_TYPE,
  JOB_ITEM_RESPONSE,
  JOB_STATUS,
  MODAL_ACTION,
} from 'models';
import AcceptExtensionReductionModal from 'modules/parent/bookings/components/AcceptExtensionReductionModal';
import {
  mapBookingColor,
  mapBookingTitle,
  mapButtonTitle,
} from 'modules/parent/bookings/helpers';
import { memo, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { useAppDispatch } from 'store/hook';
import { updateDataPaymentJob } from 'store/reducers/payments';
import { theme } from 'theme';
import { formatPrice, pluralString } from 'utils';
import { StyledJobItem } from './styles';

interface BookingItemProps {
  data: JOB_ITEM_RESPONSE;
}

const JobItem = memo(({ data }: BookingItemProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [modalAction, setModalAction] = useState<MODAL_ACTION<JOB_STATUS>>();

  const handlePaymentJob = () => {
    dispatch(
      updateDataPaymentJob({
        id: data.id,
        total: data.total,
        isTrial: data.is_trial_job,
      }),
    );
    navigate(APP_ROUTES.BOOKING.PAYMENT.to);
  };

  return (
    <StyledJobItem
      component={NavLink}
      to={APP_ROUTES.BOOKING.DETAIL.to(data.id)}
      spacing={2}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: 'start', md: 'space-between' }}
        spacing={{ xs: 4, md: 0 }}
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 1, md: 0.5 }}
        >
          <Typography variant="Web_Title_18"> {data.number}</Typography>
        </Stack>
        <Typography variant="Web_Label_18" color={mapBookingColor[data.status]}>
          {mapBookingTitle[data.status]}
        </Typography>
      </Stack>
      <Divider />
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={{ xs: 4, md: 14 }}
      >
        <Stack spacing={{ xs: 0.5 }}>
          <Typography variant="Web_Label_18" color={theme.palette.grey3.dark}>
            Start Date
          </Typography>
          <Typography variant="Web_Label_18">
            {formatDate(data.start_datetime, {
              format: 'DD/MM/YYYY (ha)',
            })}
          </Typography>
        </Stack>
        <Stack spacing={{ xs: 0.5 }}>
          <Typography variant="Web_Label_18" color={theme.palette.grey3.dark}>
            End Date
          </Typography>
          <Typography variant="Web_Label_18">
            {formatDate(data.end_datetime, {
              format: 'DD/MM/YYYY (ha)',
            })}
          </Typography>
        </Stack>
        <Stack spacing={{ xs: 0.5 }}>
          <Typography variant="Web_Label_18" color={theme.palette.grey3.dark}>
            Duration
          </Typography>
          <Typography variant="Web_Label_18">
            {pluralString('hour', data.total_hours)}
          </Typography>
        </Stack>
      </Stack>
      <Stack spacing={0.5}>
        <Typography variant="Web_Label_18" color={theme.palette.grey3.dark}>
          Address
        </Typography>
        <Typography variant="Web_Label_18" textTransform={'capitalize'}>
          {`${data.address.block_number} ${data.address.street}, #${data.address.unit_number} ${data.address.Region.name} ${data.address.postal_code}`}
        </Typography>
      </Stack>
      <Stack spacing={0.5}>
        <Typography variant="Web_Label_18" color={theme.palette.grey3.dark}>
          Sitter
        </Typography>
        <Typography variant="Web_Label_18">
          {data.Sitter.User.first_name}
        </Typography>
      </Stack>
      <Divider />
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack spacing={0.5}>
          <Typography variant="Web_Label_18" color={theme.palette.grey3.dark}>
            Total
          </Typography>
          <Typography
            variant="Small_Profile_16"
            color={theme.palette.primary.main}
          >
            {formatPrice(data.total)}
          </Typography>
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'}>
          {![
            JOB_STATUS.CANCELLED,
            JOB_STATUS.STARTED,
            JOB_STATUS.PENDING_COMPLETED,
            JOB_STATUS.COMPLETED,
          ].includes(data.status) && (
            <>
              <Button
                variant="text"
                sx={{ height: 48 }}
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                ) => {
                  e.preventDefault();
                  console.log('Cancelled');
                }}
              >
                Cancel Booking
              </Button>
              {!data.is_paid ? (
                <Button
                  variant="contained"
                  sx={{ height: 48 }}
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                  ) => {
                    e.preventDefault();
                    handlePaymentJob();
                    mapButtonTitle[data.status] === 'View Change' &&
                      setModalAction({ type: ACTION_TYPE.CUSTOM });
                  }}
                >
                  {mapButtonTitle[data.status]}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  sx={{ height: 48 }}
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                  ) => {
                    e.preventDefault();
                    navigate(APP_ROUTES.BOOKING.EDIT.to(data.id));
                  }}
                >
                  Edit Booking
                </Button>
              )}
            </>
          )}
        </Stack>
      </Stack>
      {modalAction?.type === ACTION_TYPE.CUSTOM && (
        <AcceptExtensionReductionModal _onClose={() => {}} />
      )}
    </StyledJobItem>
  );
});

export default JobItem;
