import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import {
  BoxWrapper,
  ButtonGetStart,
  LabelSubTitle,
  LabelTitle,
} from './styles';

const HowItWorks = () => {
  const navigate = useNavigate();

  const boxData = [
    'Create Request',
    'Sitter Matched',
    'Start Trial',
    'Official Sitting',
  ];

  const onStared = async () => {
    navigate(APP_ROUTES.CREATE_REQUEST.to);
    // try {
    //   await parentApi.joinACSubscription();
    //   navigate(APP_ROUTES.CREATE_REQUEST.to);
    // } catch (error) {
    //   NotifyService.error(error);
    // }
  };

  return (
    <Stack paddingY={2}>
      <Stack paddingBottom={4.75}>
        <LabelTitle>Welcome to Aunty Concierge</LabelTitle>
        <LabelSubTitle>How it works</LabelSubTitle>
      </Stack>
      <BoxWrapper>
        {boxData.map((item, index) => {
          return <Stack key={index}>{item}</Stack>;
        })}
      </BoxWrapper>
      <ButtonGetStart type="button" variant="contained" onClick={onStared}>
        Get Started
      </ButtonGetStart>
    </Stack>
  );
};

export default HowItWorks;
