import {
  DialogProps,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { CloseCircle } from 'assets';
import { theme } from 'theme';
import { StyledDialogWrapper, StyledModalBoxWrapper } from './styles';

export interface Props extends DialogProps {
  hideCloseButton?: boolean;
  forceAction?: boolean;
  maxW?: number;
}

function ModalUI({
  title,
  open,
  onClose,
  children,
  forceAction,
  hideCloseButton,
  maxW = 0,
  ...rest
}: Props) {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <StyledDialogWrapper
      open={open}
      onClose={forceAction ? undefined : onClose}
      fullScreen={isMobile}
      PaperProps={{ sx: { maxWidth: maxW || '597px', width: '100%' } }}
      {...rest}
    >
      <StyledModalBoxWrapper>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          pb={title ? 4.5 : 0}
        >
          <Typography variant="Web_Title_22">{title}</Typography>
          {!hideCloseButton && (
            <IconButton
              size="small"
              aria-label="close"
              onClick={(event) => onClose(event, 'backdropClick')}
            >
              <CloseCircle
                sx={{
                  color: theme.palette.text.secondary,
                }}
              />
            </IconButton>
          )}
        </Stack>
        {children}
      </StyledModalBoxWrapper>
    </StyledDialogWrapper>
  );
}

export default ModalUI;
