import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Stack, Typography } from '@mui/material';
import { InputField, PhoneNumberField } from 'components';
import {
  ButtonBottom,
  ButtonSubmit,
  PageTitle,
} from 'components/common/styles/auth';
import { NotifyService } from 'config/notify';
import yup from 'config/yup.custom';
import { useDebounce, useNavigateQuery } from 'hooks';
import { PHONE_NUMBER } from 'models';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { authApi } from 'services';
import { useAppDispatch } from 'store/hook';
import { setGlobalLoading } from 'store/reducers/global';
import LogoAuth from '../../components/LogoAuth';

interface ISignUpForm {
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string;
  phoneNumber: PHONE_NUMBER;
  password: string;
}

const schema = yup
  .object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Incorrect Email').notRequired(),
    phoneNumber: yup
      .object({
        countryCode: yup.string().required('Country code is required'),
        phoneNumber: yup.string().required('Phone number is required'),
      })
      .required('Phone number is required'),
    password: yup
      .string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required'),
  })
  .required();

function Register() {
  const navigateQuery = useNavigateQuery();
  const dispatch = useAppDispatch();

  const { handleSubmit, control } = useForm<ISignUpForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = useDebounce(async (data: ISignUpForm) => {
    dispatch(setGlobalLoading(true));
    await authApi
      .register({
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        country_code: data.phoneNumber?.countryCode,
        phone_number: data.phoneNumber?.phoneNumber,
        password: data.password,
      })
      .then((data: any) => {
        NotifyService.success('Success');
        navigateQuery(APP_ROUTES.AUTH.VERIFY.to, {
          phone: `${data.data.data?.phone_number}`,
        });
      })
      .catch((e) => {
        NotifyService.error(e);
      })
      .finally(() => dispatch(setGlobalLoading(false)));
  }, 500);

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
      <LogoAuth />
      <PageTitle variant="h1" mb={1}>
        Sign up as Parent
      </PageTitle>
      <Typography mb={2} variant="Web_Label_14" color="grey2.dark">
        Please enter your information correctly. Phone number verification will
        be requried.
      </Typography>
      <Stack spacing={2}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={2}
          alignItems="flex-start"
        >
          <InputField
            control={control}
            name="firstName"
            placeholder="First name*"
          />
          <InputField
            control={control}
            name="lastName"
            placeholder="Last name*"
          />
        </Stack>
        <PhoneNumberField
          control={control}
          name="phoneNumber"
          placeholder="Phone number*"
        />
        <InputField control={control} name="email" placeholder="Email" />
        <InputField
          control={control}
          name="password"
          placeholder="Password*"
          type="password"
        />
      </Stack>
      <Stack
        mt={2}
        spacing={1}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <ButtonSubmit color="primary">Sign up</ButtonSubmit>
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Typography variant="Web_Label_14" sx={{ opacity: 0.5, mb: 1.25 }}>
        Already have an account?
      </Typography>
      <ButtonBottom component={NavLink} to={APP_ROUTES.AUTH.LOGIN.to}>
        Login as Parent
      </ButtonBottom>
    </Stack>
  );
}

export default Register;
