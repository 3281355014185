import { useAuth } from 'contexts/auth';
import { Navigate, Outlet } from 'react-router';
import { APP_ROUTES } from './routes';

interface Props {
  children: any;
}

export const PrivateRoute = ({ children }: Props) => {
  const { isLoggedIn, authRedirect } = useAuth();

  if (authRedirect.current) {
    if (!isLoggedIn) {
      return <Navigate to={APP_ROUTES.AUTH.LOGIN.to} replace />;
    }
  }

  return <>{children ? children : <Outlet />}</>;
};

export default PrivateRoute;
