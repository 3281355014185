import styled from '@emotion/styled';
import { Stack } from '@mui/material';

export const EditBookingContainer = styled(Stack)`
  margin-left: auto;
  margin-right: auto;
  width: 600px;
`;

export const FormEditBookingContainer = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
