import { Stack, Typography } from '@mui/material';
import { BoldHeartIcon } from 'assets';
import { ITEM_CHECK_LIST } from 'models/sitter';
import { StyledCertificationsItem, StyledItemTypography } from './styles';

interface Props {
  item: {
    items: ITEM_CHECK_LIST[];
    section_title: string;
  };
}

const SitterPanel = ({ item }: Props) => {
  return (
    <StyledCertificationsItem direction="row" spacing={1}>
      <BoldHeartIcon color="primary" />
      <Stack pt={0.5} spacing={1}>
        <Typography variant="Web_Label_16" color="primary">
          {item.section_title}
        </Typography>
        {item.items.map((item) => (
          <StyledItemTypography key={item.id} variant="Web_Label_14">
            {item.name}
          </StyledItemTypography>
        ))}
      </Stack>
    </StyledCertificationsItem>
  );
};

export default SitterPanel;
