import {
  Avatar,
  Box,
  Container,
  Dialog,
  IconButton,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { APP_IMG, CloseDialogIcon, LogoIcon } from 'assets';
import { forwardRef } from 'react';
import { useAppSelector } from 'store/hook';
import { theme } from 'theme';
import MenuLeft from '../MenuLeft';

interface Props {
  open: boolean;
  onClose: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function MenuMobile({ open, onClose }: Props) {
  const { parent } = useAppSelector((state) => state.auth);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{ zIndex: 20, display: { xs: 'block', md: 'none' } }}
    >
      <Box
        pt={2.25}
        pb={{ xs: 2, md: 3 }}
        borderBottom="1px solid var(--light-other-stroke, rgba(0, 0, 0, 0.08))"
      >
        <Container>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <LogoIcon
              sx={{
                color: theme.palette.primary.main,
                width: { xs: '71px', md: '89px' },
                height: { xs: '51px', md: '63px' },
              }}
            />
            <IconButton
              color="primary"
              sx={{
                display: { xs: 'block', md: 'none' },
                width: '40px',
                height: '40px',
              }}
              aria-label="MenuMobile"
              onClick={onClose}
            >
              <CloseDialogIcon />
            </IconButton>
          </Stack>
        </Container>
      </Box>
      <Stack spacing={3} height={100} pt={7.5} px={3.75}>
        <Stack spacing={1.5} justifyContent="center" alignItems="center">
          <Avatar
            alt="avatar_parent"
            src={parent?.profile_picture || APP_IMG.defaultAvatar}
            sx={{ width: 90, height: 90 }}
          />
          <Box>
            <Typography
              component="p"
              textAlign="center"
              variant="Small_Profile_14"
              color={theme.palette.grey1.light}
            >
              Hello Parent
            </Typography>
            <Typography
              color={theme.palette.primary.main}
              component="p"
              textAlign="center"
              variant="Small_Profile_16"
            >
              {parent?.full_name || 'Test'}
            </Typography>
          </Box>
        </Stack>
        <MenuLeft />
      </Stack>
    </Dialog>
  );
}

export default MenuMobile;
