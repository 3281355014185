import backgroundEmpty from './bg-empty.svg';
import defaultAvatar from './default-avatar.png';
import directionDown from './direction-down.svg';
import authLayout from './layout.svg';
import americanExpress from './payments/american-express.png';
import cardsIcon from './payments/cards.png';
import masterCard from './payments/master-card.png';
import payNowIcon from './payments/paynow.png';
import visaMethod from './payments/visa.png';
import requestCreated from './request-created.png';

export const APP_IMG = {
  defaultAvatar,
  authLayout,
  backgroundEmpty,
  payNowIcon,
  cardsIcon,
  americanExpress,
  visaMethod,
  masterCard,
  requestCreated,
  icons: {
    directionDown,
  },
};

export default APP_IMG;
