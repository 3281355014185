import { Box, Stack, Typography } from '@mui/material';
import { APP_IMG } from 'assets';
import JobItem from 'components/common/JobItem';
import ScrollToLoadMore from 'components/common/ScrollToLoadMore';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getJobListAction } from 'store/reducers/job/actionTypes';
import { JobListWrapper } from './styles';

function ListJobs() {
  const dispatch = useAppDispatch();
  const { parent } = useAppSelector((state) => state.auth);
  const { jobList } = useAppSelector((state) => state.jobs);

  useEffect(() => {
    dispatch(
      getJobListAction({
        data: { limit: 10, page: 1, parent_id: parent?.parent_id },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = () => {
    dispatch(
      getJobListAction({
        data: {
          limit: 10,
          page: jobList.metadata.page + 2,
          parent_id: parent?.parent_id,
        },
      }),
    );
  };

  return (
    <Box>
      <Stack
        mb={2.5}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="Web_Title_22">Bookings</Typography>
      </Stack>

      <JobListWrapper spacing={2}>
        {!!jobList.records?.length ? (
          <ScrollToLoadMore
            data={jobList.records}
            metadata={jobList.metadata}
            loadMore={loadMore}
          >
            <Stack spacing={2}>
              {jobList.records?.map((booking) => (
                <JobItem key={booking.id} data={booking} />
              ))}
            </Stack>
          </ScrollToLoadMore>
        ) : (
          <Stack justifyContent="center" alignItems="center">
            <Box position="relative" mt={3.75}>
              <img src={APP_IMG.backgroundEmpty} alt="bg_empty" />
              <Typography
                position="absolute"
                bottom={50}
                left={0}
                sx={{ opacity: 0.5 }}
                width="326px"
                textAlign="center"
                variant="Web_Label_14"
              >
                You don’t have any bookings yet.
              </Typography>
            </Box>
          </Stack>
        )}
      </JobListWrapper>
    </Box>
  );
}

export default ListJobs;
