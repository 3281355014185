import { Stack } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { APP_IMG } from 'assets';
import StripeFormProvider from './FormProvider';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ??
  'pk_test_51LhV7VHS1kIWkcLcUgb0ZEB8ueAQWgLxP6FWwFCp3S2zw2FvJMpk5ha0rIqetxIikJgEur2HFhvZyvKEKkvwNYZA00CDp28vdW';
const stripePromise = loadStripe(PUBLISHABLE_KEY);
const fontFamily =
  'https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap';

const PaymentStripe = () => {
  const options: StripeElementsOptions = {
    // clientSecret: clientSecret,
    fonts: [{ cssSrc: fontFamily }],
  };

  return (
    <Stack spacing={1.5} width={'100%'}>
      <Stack flexDirection="row" gap={1.5}>
        <img src={APP_IMG.visaMethod} alt="visa-method" />
        <img src={APP_IMG.masterCard} alt="master-card" />
        <img src={APP_IMG.americanExpress} alt="american-express" />
      </Stack>
      <Elements stripe={stripePromise} options={options}>
        <StripeFormProvider />
      </Elements>
    </Stack>
  );
};

export default PaymentStripe;
