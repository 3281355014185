import moment from 'moment';
import { OPTION_ITEM } from './common';
import { ADDRESS_LIST_RESPONSE } from './parent/address';

export enum REQUEST_MODAL_TYPE {
  TERMS = 'TERMS',
  ADDRESS = 'ADDRESS',
}

export enum ADDRESS_MODAL_TYPE {
  ADD = 'add',
  EDIT = 'edit',
}

/* =========== INTERFACE ============ */

export interface DATA_CREATE_REQUEST_SUMMARY {
  addChild: ADD_CHILD_DATA;
  requestDetail: REQUEST_DETAIL_DATA;
  yourSignature: string;
}

export interface AN_CHILD_ITEM {
  id: string;
  name: string;
  age: OPTION_ITEM<number>;
}

export interface ADD_CHILD_DATA {
  children: AN_CHILD_ITEM[];
  isAccepted: boolean;
}

export interface REQUEST_DETAIL_ADDRESS {
  id: string | number;
  address: string;
}
export interface REQUEST_DETAIL_PET {
  id: string;
  name: string;
}
export interface REQUEST_DETAIL_DATA {
  startDate: moment.Moment;
  endDate: moment.Moment;
  startTime: Date;
  endTime: Date;
  dayOfWeeks: string[];
  specialRequirements: string;
  pets: string[];
  children: AN_CHILD_ITEM[];
  address: ADDRESS_LIST_RESPONSE;
  isAccepted: boolean;
}

export interface PARAMS_REQUEST_DETAIL {
  start_datetime: string;
  end_datetime: string;
  days_of_week: number[];
  address_id: string;
  children_ids: string[];
  pets: {
    cats: boolean;
    dogs: boolean;
  };
  special_requirements: string;
}
