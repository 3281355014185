import { PayloadActionData } from 'models';
import { ADDRESS_PARAMS_REQUEST } from 'models/parent/address';
import { ADD_CHILDREN_REQUEST_TYPE } from 'models/parent/children';

export const GET_CHILDREN_LIST_REQUEST = 'GET_CHILDREN_LIST_REQUEST';

export const DELETE_CHILDREN_REQUEST = 'DELETE_CHILDREN_REQUEST';

export const ADD_CHILDREN_REQUEST = 'ADD_CHILDREN_REQUEST';

export const GET_ADDRESS_LIST_REQUEST = 'GET_ADDRESS_LIST_REQUEST';

export const ADD_ADDRESS_REQUEST = 'ADD_ADDRESS_REQUEST';

export const EDIT_ADDRESS_REQUEST = 'EDIT_ADDRESS_REQUEST';

export const GET_REGION_LIST_REQUEST = 'GET_REGION_LIST_REQUEST';

export const GET_ADDRESS_BY_CODE = 'GET_ADDRESS_BY_CODE';

export const getChildrenListAction = () => ({
  type: GET_CHILDREN_LIST_REQUEST,
});

export const addChildrenAction = (
  payload: PayloadActionData<ADD_CHILDREN_REQUEST_TYPE>,
) => ({
  type: ADD_CHILDREN_REQUEST,
  payload,
});

export const deleteChildrenAction = (
  payload: PayloadActionData<{ id: string }>,
) => ({
  type: DELETE_CHILDREN_REQUEST,
  payload,
});

export const getAddressListAction = () => ({
  type: GET_ADDRESS_LIST_REQUEST,
});

export const addAddressAction = (
  payload: PayloadActionData<ADDRESS_PARAMS_REQUEST>,
) => ({
  type: ADD_ADDRESS_REQUEST,
  payload,
});

export const editAddressAction = (
  payload: PayloadActionData<ADDRESS_PARAMS_REQUEST>,
) => ({
  type: EDIT_ADDRESS_REQUEST,
  payload,
});

export const getRegionListAction = () => ({
  type: GET_REGION_LIST_REQUEST,
});

export const getAddressByPostalCodeAction = (
  payload: PayloadActionData<{ id: number }>,
) => ({
  type: GET_ADDRESS_BY_CODE,
  payload,
});
