import { PayloadActionData } from 'models';
import {
  ATTACH_PAYMENT_METHOD_PAYLOAD,
  BUY_PACKAGE_PAYLOAD,
  SEARCH_FEE_PAYLOAD,
  TRIAL_JOB_PAYLOAD,
} from 'models/payments';

export const PAY_FOR_SEARCH_FEE_REQUEST = 'PAY_FOR_SEARCH_FEE_REQUEST';
export const PAY_FOR_BUY_PACKAGE_REQUEST = 'PAY_FOR_BUY_PACKAGE_REQUEST';
export const ATTACH_PAYMENT_METHOD_REQUEST = 'ATTACH_PAYMENT_METHOD_REQUEST';
export const GET_FEE_SUMMARY_REQUEST = 'GET_FEE_SUMMARY_REQUEST';
export const PAY_FOR_TRIAL_JOB_REQUEST = 'PAY_FOR_TRIAL_JOB_REQUEST';
export const PAY_FOR_NORMAL_JOB_REQUEST = 'PAY_FOR_NORMAL_JOB_REQUEST';
export const GET_LIST_CARD_REQUEST = 'GET_LIST_CARD_REQUEST';

export const paySearchFeeAction = (
  payload: PayloadActionData<SEARCH_FEE_PAYLOAD>,
) => ({
  type: PAY_FOR_SEARCH_FEE_REQUEST,
  payload,
});

export const attachPaymentMethodAction = (
  payload: PayloadActionData<ATTACH_PAYMENT_METHOD_PAYLOAD>,
) => ({
  type: ATTACH_PAYMENT_METHOD_REQUEST,
  payload,
});

export const getFeeSummaryAction = () => ({
  type: ATTACH_PAYMENT_METHOD_REQUEST,
});

export const payForBuyPackageAction = (
  payload: PayloadActionData<BUY_PACKAGE_PAYLOAD>,
) => ({
  type: PAY_FOR_BUY_PACKAGE_REQUEST,
  payload,
});

export const payForTrialJobAction = (
  payload: PayloadActionData<TRIAL_JOB_PAYLOAD>,
) => ({
  type: PAY_FOR_TRIAL_JOB_REQUEST,
  payload,
});

export const payForNormalJobAction = (
  payload: PayloadActionData<TRIAL_JOB_PAYLOAD>,
) => ({
  type: PAY_FOR_NORMAL_JOB_REQUEST,
  payload,
});

export const getListCardsAction = () => ({
  type: GET_LIST_CARD_REQUEST,
});
