import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { USER_PROFILE } from 'models';

export interface AuthenticationType {
  parent: USER_PROFILE;
  loadingAuth: boolean;
}

const initialState: AuthenticationType = {
  parent: null,
  loadingAuth: true,
};

const authenticationSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setParentProfile(
      state: AuthenticationType,
      action: PayloadAction<USER_PROFILE>,
    ) {
      state.parent = action.payload;
    },
    setLoadingAuth(state: AuthenticationType, action: PayloadAction<boolean>) {
      state.loadingAuth = action.payload;
    },
  },
});

// Actions
export const { setParentProfile, setLoadingAuth } = authenticationSlice.actions;

export default authenticationSlice.reducer;
