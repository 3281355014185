import {
  Avatar,
  Box,
  Container,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { APP_IMG, LogoIcon, MenuMobileIcon } from 'assets';
import { useAuth } from 'contexts/auth';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { useAppSelector } from 'store/hook';
import { theme } from 'theme';
import MenuMobile from '../Menu/MenuMobile';
import AccountPendingInfo from './components/AccountPendingInfo';
import { MenuStyled } from './styles';

export const Header = () => {
  const { logout } = useAuth();
  const { parent } = useAppSelector((state) => state.auth);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openStatusInfo, setOpenStatusInfo] = useState<boolean>(false);
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setOpenMenu(false);
  }, [location]);

  return (
    <Box
      pt={2.25}
      pb={{ xs: 2, md: 3 }}
      component="section"
      position="sticky"
      top={0}
      zIndex={10}
      borderBottom="1px solid var(--light-other-stroke, rgba(0, 0, 0, 0.08))"
      bgcolor={theme.palette.white.light}
    >
      <Container>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={5} alignItems="center">
            <Link to={APP_ROUTES.OVERVIEW.to}>
              <LogoIcon
                sx={{
                  color: theme.palette.primary.main,
                  width: { xs: '71px', md: '89px' },
                  height: { xs: '51px', md: '63px' },
                }}
              />
            </Link>
          </Stack>
          <AccountPendingInfo
            open={openStatusInfo}
            onClose={() => setOpenStatusInfo(false)}
          />
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box>
              <Typography
                component="p"
                textAlign="right"
                variant="Small_Profile_14"
              >
                Hello Parent
              </Typography>
              <Typography
                color={theme.palette.primary.main}
                component="p"
                variant="Small_Profile_16"
              >
                {parent?.full_name || 'Test'}
              </Typography>
            </Box>
            <IconButton onClick={handleClick}>
              <Avatar
                alt="avatar_parent"
                src={parent?.profile_picture || APP_IMG.defaultAvatar}
                sx={{ width: 35, height: 35 }}
              />
            </IconButton>
            <MenuStyled
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={logout}>
                <Typography variant="Title_Menu_14">Logout</Typography>
              </MenuItem>
            </MenuStyled>
            <IconButton
              color="primary"
              sx={{
                display: { xs: 'block', md: 'none' },
                width: '40px',
                height: '40px',
              }}
              aria-label="MenuMobile"
              onClick={() => setOpenMenu(true)}
            >
              <MenuMobileIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Container>
      <MenuMobile open={openMenu} onClose={() => setOpenMenu(false)} />
    </Box>
  );
};

export default Header;
