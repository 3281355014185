import { Button, Stack, Typography } from '@mui/material';
import { CheckCircleIcon, CloseCircle, HelpCircleIcon } from 'assets';
import ModalUISmall from 'components/common/ModalUISmall';
import { ReactNode, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledButtonWrapper } from './styles';

interface PopupConfirmProps {
  typeIcon?: 'cancel' | 'help' | 'close' | 'check' | '';
  onClose: () => void;
  title: string;
  content: string | ReactNode;
  hrefButton?: any;
  onSubmit?: () => void;
  textButton: string;
  textButton2?: string;
}

const PopupConfirm = memo(
  ({
    typeIcon = '',
    title,
    content,
    onClose,
    hrefButton,
    onSubmit,
    textButton = 'Confirm',
    textButton2,
  }: PopupConfirmProps) => {
    const navigate = useNavigate();

    const handleClickConfirm = () => {
      onClose();
      if (hrefButton) return navigate(hrefButton);
      onSubmit?.();
    };

    const getPopupIcon = () => {
      const styleIcon = { width: '55px', height: '55px' };
      switch (typeIcon) {
        case 'check':
          return <CheckCircleIcon color="primary" sx={styleIcon} />;
        case 'help':
          return <HelpCircleIcon color="primary" sx={styleIcon} />;
        case 'close':
          return <CloseCircle color="primary" sx={styleIcon} />;
        case 'cancel':
          return <CloseCircle color="primary" sx={styleIcon} />;
        default:
          return <></>;
      }
    };

    return (
      <ModalUISmall open hideCloseButton onClose={onClose}>
        <Stack spacing={2} justifyContent="center" alignItems="center">
          {getPopupIcon()}
          <Typography variant="Web_Title_18" color="primary.main">
            {title}
          </Typography>
          <Typography textAlign={'center'}>{content}</Typography>
          <StyledButtonWrapper>
            {textButton2 && (
              <Button onClick={onClose} variant="contained" className="btn-2">
                {textButton2}
              </Button>
            )}
            <Button onClick={handleClickConfirm} variant="contained">
              {textButton}
            </Button>
          </StyledButtonWrapper>
        </Stack>
      </ModalUISmall>
    );
  },
);

export default PopupConfirm;
