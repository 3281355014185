import { toast, ToastOptions } from 'react-toastify'

const TOAST: { [key: string]: ToastOptions } = {
  error: {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  },
  success: {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  },
}

export class NotifyService {
  static error(e: any) {
    const data = getDataError(e)
    let message = data?.error || data?.message || e
    if (data?.data?.errors?.[0]) message = data.data.errors[0]
    toast.error(message, TOAST.error)
  }

  static success(r: any) {
    toast.success(
      r?.data?.data?.success || r?.data?.message || r,
      TOAST.success,
    )
  }
  static warning(r: any) {
    toast.warning(r?.data?.data?.failed || r?.data?.message || r, TOAST.success)
  }
}

export const getDataError = <T extends any = any>(e: any) => {
  return (e?.response?.data || e) as T
}
