import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, IconButton, Typography } from '@mui/material';
import { CloseCircle } from 'assets';
import { InputField, SelectField } from 'components';
import CheckboxField from 'components/form-control/CheckboxField';
import yup from 'config/yup.custom';
import { SYS_MESS } from 'constants/systemMessage';
import { cloneDeep } from 'lodash';
import { ADD_CHILD_DATA } from 'models/create-request';
import { useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  addChildrenAction,
  deleteChildrenAction,
  getChildrenListAction,
} from 'store/reducers/parent/actionTypes';
import {
  updateBodyCreateRequest,
  updateStepTrial,
} from 'store/reducers/trial-request';
import { theme } from 'theme';
import { ageRangeOptions } from '../../helpers';
import {
  AddChildButton,
  AnChildItem,
  FormContainer,
  LabelCheckbox,
  LabelWrap,
  MoreActions,
  NextButton,
} from './styles';

const schema = yup
  .object()
  .shape({
    children: yup.array().of(
      yup.object().shape({
        name: yup.string().required(SYS_MESS.ERROR.NAME_OF_CHILD_REQUIRED),
        age: yup.mixed().required(SYS_MESS.ERROR.AGE_REQUIRED),
      }),
    ),
    isAccepted: yup
      .boolean()
      .test(
        'isChecked',
        SYS_MESS.ERROR.CHECKBOX_REQUIRED,
        (value) => value === true,
      )
      .required(SYS_MESS.ERROR.CHECKBOX_REQUIRED),
  })
  .required();

function RequestAddChildren() {
  const dispatch = useAppDispatch();
  const { bodyCreateRequest } = useAppSelector((state) => state.trialRequest);
  const { childrenList } = useAppSelector((state) => state.parent);

  const form = useForm<ADD_CHILD_DATA>({
    resolver: yupResolver(schema),
    defaultValues: {
      children: [{ id: '', name: '', age: null }],
      isAccepted: false,
    },
  });

  const { handleSubmit, control, setValue, watch } = form;
  const { append, remove } = useFieldArray({
    control,
    name: 'children',
  });

  const addChild = () => {
    append({ id: '', name: '', age: null });
  };

  const removeChild = async (index: number, id: string) => {
    if (!id) remove(index);
    else
      dispatch(
        deleteChildrenAction({
          data: { id },
          onSuccess: () => {
            dispatch(getChildrenListAction());
          },
        }),
      );
  };

  const numberChild = document.getElementById('number-child')?.offsetWidth;
  const deleteChild = document.getElementById('delete-child')?.offsetWidth;
  const widthCenter = useMemo(() => {
    if (typeof (numberChild + deleteChild) !== 'number') return 0;
    return `calc(100% - ${numberChild + deleteChild + 32}px)`;
  }, [numberChild, deleteChild]);

  const onSubmit = async (data: ADD_CHILD_DATA) => {
    const isHasNewChildren = data.children.some((item) => item.id === '');
    if (!isHasNewChildren) {
      dispatch(updateStepTrial(2));
      dispatch(
        updateBodyCreateRequest({ ...bodyCreateRequest, addChild: data }),
      );
    } else {
      const _children = cloneDeep(
        data.children.filter((item) => item.id === ''),
      );
      const childrenPromises = _children.map((child) =>
        dispatch(
          addChildrenAction({
            data: {
              full_name: child.name,
              age_range: child.age.id,
            },
          }),
        ),
      );

      await Promise.all(childrenPromises);
      dispatch(getChildrenListAction());
      dispatch(updateStepTrial(2));
      dispatch(
        updateBodyCreateRequest({ ...bodyCreateRequest, addChild: data }),
      );
    }
  };

  useEffect(() => {
    dispatch(getChildrenListAction());
  }, [dispatch]);

  useEffect(() => {
    if (bodyCreateRequest?.addChild.children) {
      setValue('isAccepted', bodyCreateRequest.addChild.isAccepted);
    }
    if (!childrenList) return;
    const _children = childrenList?.map((child) => {
      return {
        id: child.id,
        name: child.full_name,
        age: ageRangeOptions.find((item) => item.id === child.age_range),
      };
    });

    setValue('children', _children);
  }, [childrenList, bodyCreateRequest, setValue]);

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <LabelWrap>
        <Typography variant="Small_Body_16" width={'48%'}>
          Name of Child
        </Typography>
        <Typography variant="Small_Body_16" width={'48%'}>
          Age
        </Typography>
      </LabelWrap>
      {watch('children').map((child, index) => {
        return (
          <AnChildItem key={child.id}>
            <Typography
              variant="Components_UnstyledContainer"
              id="number-child"
            >
              #{index + 1}
            </Typography>
            <InputField
              control={control}
              name={`children.${index}.name`}
              placeholder="When is your child’s name?"
            />
            <SelectField
              control={control}
              name={`children.${index}.age`}
              placeholder="Select one"
              options={ageRangeOptions}
            />
            <IconButton
              id="delete-child"
              sx={{ padding: 0 }}
              onClick={() => removeChild(index, child.id)}
            >
              <CloseCircle sx={{ color: theme.palette.grey1.dark }} />
            </IconButton>
          </AnChildItem>
        );
      })}
      <MoreActions width={widthCenter}>
        <AddChildButton onClick={addChild}>+ Add another child</AddChildButton>
        <Divider sx={{ borderColor: '#D9D9D9' }} />
        <CheckboxField
          name="isAccepted"
          control={control}
          label={
            <LabelCheckbox>
              I acknowledge that I have read and understood the
              <span>&nbsp;Data Protection Notice&nbsp;</span>, and consent to
              the collection, use and disclosure of my personal data by Aunty SG
              for the purposes set out in the Notice.
            </LabelCheckbox>
          }
        />
        <NextButton variant="contained" type="submit">
          Next
        </NextButton>
      </MoreActions>
    </FormContainer>
  );
}

export default RequestAddChildren;
