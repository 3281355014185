import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, Typography } from '@mui/material';
import { InputField } from 'components';
import ModalUI from 'components/common/ModalUI';
import { NotifyService } from 'config/notify';
import yup from 'config/yup.custom';
import { useDebounce } from 'hooks';
import { useForm } from 'react-hook-form';
import { profileApi } from 'services';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setGlobalLoading } from 'store/reducers/global';

interface IChangePasswordForm {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface Props {
  openPop: boolean;
  onClose: () => void;
}

const schema = yup
  .object({
    currentPassword: yup
      .string()
      .min(8, 'Password must be at least 8 characters')
      .required('Current password is required'),
    newPassword: yup
      .string()
      .min(8, 'Password must be at least 8 characters')
      .required('New password is required'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('newPassword')], 'Password does not match')
      .required('Confirm password is required'),
  })
  .required();

export const PopupChangePassword = ({ openPop, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const { globalLoading } = useAppSelector((state) => state.global);

  const { handleSubmit, control, reset } = useForm<IChangePasswordForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleCloseLog = () => {
    reset();
    onClose();
  };

  const onSubmit = useDebounce(async (data: IChangePasswordForm) => {
    if (globalLoading) return;
    dispatch(setGlobalLoading(true));
    await profileApi
      .changePassword({
        old_password: data.currentPassword || '',
        new_password: data.newPassword || '',
        confirm_password: data.confirmPassword || '',
      })
      .then(() => {
        handleCloseLog();
        NotifyService.success('Success');
      })
      .catch((e) => {
        NotifyService.error(e);
      })
      .finally(() => {
        dispatch(setGlobalLoading(false));
      });
  }, 500);

  return (
    <ModalUI
      open={openPop}
      onClose={handleCloseLog}
      title="Change Password"
      PaperProps={{ sx: { maxWidth: '435px', width: '100%' } }}
    >
      <Stack component="form" spacing={2} onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="Small_Profile_14">
          Change your password below
        </Typography>
        <InputField
          helperTextProps={{ sx: { textAlign: 'left' } }}
          control={control}
          name="currentPassword"
          placeholder="Current password"
          type="password"
        />
        <InputField
          helperTextProps={{ sx: { textAlign: 'left' } }}
          control={control}
          name="newPassword"
          placeholder="New Password"
          type="password"
        />
        <InputField
          helperTextProps={{ sx: { textAlign: 'left' } }}
          control={control}
          name="confirmPassword"
          placeholder="Confirm password"
          type="password"
        />
        <Stack alignItems="flex-end">
          <Button
            variant="contained"
            type="submit"
            sx={{ width: { xs: '100%', md: '174px' } }}
          >
            Change
          </Button>
        </Stack>
      </Stack>
    </ModalUI>
  );
};

export default PopupChangePassword;
