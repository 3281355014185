import { SvgIcon, SvgIconProps } from '@mui/material';

export function ChevronLeftIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.27416 14.619C8.00895 14.4068 7.96595 14.0198 8.17812 13.7546L11.1506 10.039L8.17812 6.3234C7.96595 6.05818 8.00895 5.67119 8.27416 5.45902C8.53937 5.24685 8.92636 5.28985 9.13853 5.55506L12.4184 9.65484C12.598 9.87944 12.598 10.1986 12.4184 10.4232L9.13853 14.523C8.92636 14.7882 8.53937 14.8312 8.27416 14.619Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default ChevronLeftIcon;
