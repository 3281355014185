import { APIInstance } from 'config';
import { API } from 'constants/apiEndpoints';
import { TOKEN_KEY } from 'constants/global';
import { RESPONSE_TYPE } from 'models';
import {
  ATTACH_PAYMENT_METHOD_PAYLOAD,
  BUY_PACKAGE_PAYLOAD,
  CARD_LIST_RESPONSE,
  CREDIT_BALANCE_RESPONSE,
  PAYMENT_RESPONSE,
  SEARCH_FEE_PAYLOAD,
  TRIAL_JOB_PAYLOAD,
} from 'models/payments';
import { getCookies } from 'utils';

export const paymentApi = {
  getAllCards: async () => {
    return APIInstance.get<RESPONSE_TYPE<CARD_LIST_RESPONSE[]>>(
      `${API.PAYMENT.GET_ALL_CARDS}`,
    );
  },
  getCreditBalance: async (accessToken?: string) => {
    return APIInstance.get<RESPONSE_TYPE<CREDIT_BALANCE_RESPONSE>>(
      `${API.PAYMENT.GET_CREDIT_BALANCE}`,
      {
        headers: {
          Authorization:
            'Bearer ' + (getCookies(TOKEN_KEY.ACCESS_TOKEN) ?? accessToken),
        },
      },
    );
  },
  searchFee: async (data: SEARCH_FEE_PAYLOAD) => {
    return APIInstance.post<RESPONSE_TYPE<PAYMENT_RESPONSE>>(
      API.PAYMENT.PAY_SEARCH_FEE,
      data,
    );
  },
  attachPaymentMethod: async (data: ATTACH_PAYMENT_METHOD_PAYLOAD) => {
    return APIInstance.post<RESPONSE_TYPE>(
      API.PAYMENT.ATTACH_PAYMENT_METHOD,
      data,
    );
  },
  createPaymentMethod: async (data: any): Promise<any> => {
    return APIInstance.post(API.PAYMENT.CREATE_PAYMENT_METHOD, data);
  },
  getSearchFeeSummary: async () => {
    return APIInstance.get<RESPONSE_TYPE>(`${API.PAYMENT.FEE_SUMMARY}`);
  },
  buyPackage: async (data: BUY_PACKAGE_PAYLOAD) => {
    return APIInstance.post<RESPONSE_TYPE<PAYMENT_RESPONSE>>(
      API.PAYMENT.BUY_PACKAGE,
      data,
    );
  },
  trialJob: async (data: TRIAL_JOB_PAYLOAD) => {
    return APIInstance.post<RESPONSE_TYPE<PAYMENT_RESPONSE>>(
      API.PAYMENT.TRIAL_JOB,
      data,
    );
  },
  normalJob: async (data: TRIAL_JOB_PAYLOAD) => {
    return APIInstance.post<RESPONSE_TYPE<PAYMENT_RESPONSE>>(
      API.PAYMENT.NORMAL_JOB,
      data,
    );
  },
};
