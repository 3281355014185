import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { theme } from 'theme';

export const StyledBookingDetailWrapper = styled(Stack)`
  position: relative;
`;

export const StyledBackgroundOverlay = styled(Stack)`
  height: 160px;
  background: ${theme.palette.primary.main};
  & > a {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 30px;
    width: 800px;
    margin-left: auto !important;
    margin-right: auto !important;
    text-decoration: none;
    color: ${theme.palette.white.light};
    gap: 8px;
    ${theme.breakpoints.down('lg')} {
      width: 70%;
    }
    ${theme.breakpoints.down('sm')} {
      width: 90%;
    }
  }
`;

export const StyledContentWrapper = styled(Stack)`
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 800px;
  margin-left: auto !important;
  margin-right: auto !important;
  ${theme.breakpoints.down('lg')} {
    width: 70%;
  }
  ${theme.breakpoints.down('sm')} {
    width: 90%;
  }
`;

export const StyledJobWrapper = styled(Stack)`
  color: ${theme.palette.black.dark};
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #959595;
  border-radius: 8px;
  padding: 24px;
  background: ${theme.palette.white.light};
`;

export const StyledBoxChildrenWrapper = styled(Stack)`
  flex-direction: row;
  align-items: center;
  background: ${theme.palette.light.shades4};
  gap: 32px;
  height: 70px;
  padding-left: 22px;
  ${theme.breakpoints.down('sm')} {
    padding-left: 0px;
    gap: 10px;
    justify-content: center;
  }
  & .avtChildren {
    background: ${theme.palette.primary.main};
  }
  & .nameChildren {
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
  & .ageChildren {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
`;

export const StyledSitterWrapper = styled(Stack)`
  gap: 16px;
  height: 138px;
  background: ${theme.palette.white.light};
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #959595;
  border-radius: 8px;
  padding: 24px;
  & .sitterTitle {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.1px;
    opacity: 0.5;
  }
  & .sitterName {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${theme.palette.primary.main};
  }
  & .totalExp {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${theme.palette.grey1.light};
  }
  & .sitterPicture {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
`;
