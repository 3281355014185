import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PAGINATION2_TYPE } from 'models';
import {
  AVAILABLE_PACKAGE_ITEM_TYPE,
  SUBSCRIPTION_PURCHASED_RESPONSE,
} from 'models/credits';

export interface CreditsType {
  currentBalance: number;
  availablePackages: PAGINATION2_TYPE<AVAILABLE_PACKAGE_ITEM_TYPE[]>;
  selectedPackageId: string;
  subscriptionPurchased: SUBSCRIPTION_PURCHASED_RESPONSE;
}

const initialState: CreditsType = {
  currentBalance: 0,
  availablePackages: null,
  selectedPackageId: '',
  subscriptionPurchased: {
    metadata: {
      page: 0,
      limit: 0,
      page_count: 0,
      total_pages: 0,
      total_count: 0,
    },
    records: [],
  },
};

const creditSlice = createSlice({
  name: 'credits',
  initialState,
  reducers: {
    setDataBalanceCredit(state: CreditsType, action: PayloadAction<number>) {
      state.currentBalance = action.payload;
    },
    setAvailablePackage(
      state: CreditsType,
      action: PayloadAction<PAGINATION2_TYPE<AVAILABLE_PACKAGE_ITEM_TYPE[]>>,
    ) {
      state.availablePackages = action.payload;
    },
    updateSelectedPackageId(state: CreditsType, action: PayloadAction<string>) {
      state.selectedPackageId = action.payload;
    },
    updateSubscriptionPurchased(
      state: CreditsType,
      action: PayloadAction<SUBSCRIPTION_PURCHASED_RESPONSE>,
    ) {
      if (action.payload.metadata.page <= 1) {
        state.subscriptionPurchased = action.payload;
      } else {
        state.subscriptionPurchased = action.payload;
        state.subscriptionPurchased = {
          ...state.subscriptionPurchased,
          ...action.payload,
          records: state.subscriptionPurchased.records.concat(
            action.payload.records,
          ),
        };
      }
    },
  },
});

export const {
  setDataBalanceCredit,
  setAvailablePackage,
  updateSelectedPackageId,
  updateSubscriptionPurchased,
} = creditSlice.actions;

export default creditSlice.reducer;
