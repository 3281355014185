import { useMemo } from 'react';
import { useAppSelector } from 'store/hook';
import { LabelSubTitle, LabelTitle } from '../styles';
import RequestAddChildren from './components/RequestAddChildren';
import RequestDetail from './components/RequestDetail';
import RequestPayment from './components/RequestPayment';
import RequestSuccess from './components/RequestSuccess';
import { CreateRequestContainer, RequestHeader } from './styles';

function CreateRequest() {
  const { stepTrial } = useAppSelector((state) => state.trialRequest);

  const getTitle = useMemo(() => {
    switch (stepTrial) {
      case 1:
        return {
          title: 'Step 1: Add your children',
          subTitle: 'Add your child so we can assign the best nanny for you',
        };
      case 2:
        return {
          title: 'Step 2: Request details',
          subTitle: 'Let us know your request so we can find best matches',
        };
      case 3:
        return {
          title: 'Step 3: Payment',
          subTitle: 'This is a one time search fee to fulfill your request',
        };
      default:
        return {
          title: '',
          subTitle: '',
        };
    }
  }, [stepTrial]);

  const getActiveUI = useMemo(() => {
    switch (stepTrial) {
      case 1:
        return <RequestAddChildren />;
      case 2:
        return <RequestDetail />;
      case 3:
        return <RequestPayment />;
      default:
        return <RequestSuccess />;
    }
  }, [stepTrial]);

  return (
    <CreateRequestContainer>
      <RequestHeader>
        <LabelTitle>{getTitle.title}</LabelTitle>
        <LabelSubTitle>{getTitle.subTitle}</LabelSubTitle>
      </RequestHeader>
      {getActiveUI}
    </CreateRequestContainer>
  );
}

export default CreateRequest;
