import {
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormHelperText,
  RadioGroupProps,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { RadioCheckIcon, RadioUnCheckIcon } from 'assets';
import { useMemo } from 'react';
import { Control, useController } from 'react-hook-form';
import {
  FormControlContainer,
  RadioGroupContainer,
  RadioIconWrap,
} from './styles';

interface RADIO_OPTION_ITEM {
  label: string;
  value: string;
}

interface RadioGroupFieldProps extends Partial<FormControlLabelProps> {
  formControlProps?: FormControlProps;
  radioGroupProps?: RadioGroupProps;
  options: RADIO_OPTION_ITEM[] | any[];
  name: string;
  control: Control<any> | any;
  optionValue?: string;
  optionLabel?: string;
  defaultValue?: string;
  hideHelper?: boolean;
  sx?: SxProps;
}

const RadioGroupField = ({
  formControlProps = {},
  radioGroupProps = {},
  name,
  options,
  control,
  optionValue,
  optionLabel,
  defaultValue,
  hideHelper = false,
  ...rest
}: RadioGroupFieldProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const defaultVal = useMemo(() => {
    return defaultValue;
  }, [defaultValue]);

  return (
    <FormControlContainer {...formControlProps}>
      <RadioGroupContainer
        {...radioGroupProps}
        name="radio-buttons-group"
        value={field.value ?? ''}
        onChange={field.onChange}
        defaultValue={defaultVal}
      >
        {options.map((item, index) => {
          return (
            <FormControlLabel
              key={index}
              sx={{ gap: '10px' }}
              {...rest}
              value={item?.[optionValue as string] || item.value}
              label={item[optionLabel] || item.label}
              control={
                <RadioIconWrap
                  icon={<RadioUnCheckIcon sx={{ color: 'gray.dark_grey' }} />}
                  checkedIcon={
                    <RadioCheckIcon sx={{ color: 'primary.main' }} />
                  }
                />
              }
            />
          );
        })}
      </RadioGroupContainer>
      {!hideHelper && error?.message && (
        <FormHelperText error={!!error.message}>{error.message}</FormHelperText>
      )}
    </FormControlContainer>
  );
};

export default RadioGroupField;
