import styled from '@emotion/styled';
import { Box, Container, Stack, Typography } from '@mui/material';
import { theme } from 'theme';

export const TopBox = styled(Box)`
  height: 159px;
  padding-top: 37px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: ${theme.palette.primary.main};
  position: relative;
  /* ${theme.breakpoints.down('md')} {
    height: 139px;
    align-items: center;
    padding: 40px 70px;
  } */
`;

export const StyledContainer = styled(Container)`
  position: relative;
  top: -70px;
  ${theme.breakpoints.down('md')} {
    top: -90px;
  }
`;

export const StyledBoxProfile = styled(Stack)`
  background: ${theme.palette.white.light};
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 24px 20px 26px;
  ${theme.breakpoints.down('md')} {
    padding: 19px 20px;
  }
`;

export const StyledLink = styled(Stack)`
  cursor: pointer;
  text-decoration: none;
  color: ${theme.palette.white.dark};
  position: absolute;
  top: -45px;
  left: 24px;
  ${theme.breakpoints.down('md')} {
    left: 20px;
  }
`;

export const StyledTypography = styled(Typography)`
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
  }
`;
