import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { theme } from 'theme';

export const StyledStripeTextField = styled(TextField)`
  & .MuiInputBase-root {
    height: 48px;
    border: 1px solid ${theme.palette.light.stroke};
    border-radius: 8px;
    background-color: ${theme.palette.backgrounds.primary};
    ::before,
    ::after,
    :hover,
    :focus {
      border-bottom: 1px solid ${theme.palette.light.stroke} !important;
      background-color: ${theme.palette.backgrounds.primary} !important;
    }
    & .MuiInputBase-input {
      padding-top: 12px;
    }
  }
  & .MuiFormLabel-root {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: ${theme.palette.light.placeholder};
  }
  & .MuiFormHelperText-root {
    padding-top: 2px;
  }
`;
