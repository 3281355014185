import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabelProps,
  FormControlProps,
  FormHelperText,
  SxProps,
} from '@mui/material';
import { CheckIcon, UnCheckIcon } from 'assets';
import { ReactNode } from 'react';
import { Control, FieldError, useController } from 'react-hook-form';
import { FormControlLabelStyled } from './styles';

interface CheckboxFieldProps extends CheckboxProps {
  label?: string | ReactNode;
  sx?: SxProps;
  fullWidth?: boolean;
  control?: Control<any>;
  hideHelper?: boolean;
  errorMess?: string;
  rootProps?: Partial<FormControlProps>;
  formControlLabel?: Partial<FormControlLabelProps> & { $error?: boolean };
  getErrorMess?: (error: FieldError, value: string) => string;
  alignItems?: string;
}

function CheckboxField({
  label,
  name,
  fullWidth,
  rootProps,
  checked: externalValue,
  onChange: externalOnChange,
  control,
  hideHelper,
  errorMess,
  getErrorMess,
  formControlLabel,
  alignItems = 'center',
  ...rest
}: CheckboxFieldProps) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = control
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useController({ name, control })
    : {
        field: {
          onChange: externalOnChange as (...event: any[]) => void,
          value: externalValue,
          onBlur: undefined,
          ref: undefined,
        },
        fieldState: { error: undefined },
      };

  return (
    <FormControl
      fullWidth={fullWidth}
      sx={{ alignItems: 'flex-start', ...(rootProps?.sx || {}) }}
      {...rootProps}
    >
      <FormControlLabelStyled
        $alignItems={alignItems}
        checked={!!value}
        control={
          <Checkbox
            icon={<UnCheckIcon />}
            checkedIcon={<CheckIcon />}
            {...rest}
          />
        }
        $error={!!error}
        label={label}
        inputRef={ref}
        onBlur={onBlur}
        onChange={onChange}
        {...formControlLabel}
      />
      {!hideHelper && (error || errorMess) && (
        <FormHelperText error={!!error || !!errorMess}>
          {getErrorMess
            ? getErrorMess(error, value)
            : error?.message || errorMess}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default CheckboxField;
