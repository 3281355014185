import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { PAGINATION2_TYPE, PayloadActionData, RESPONSE_TYPE } from 'models';
import {
  AVAILABLE_PACKAGE_ITEM_TYPE,
  GET_SUBSCRIPTION_PURCHASED_PARAMS,
  SUBSCRIPTION_PURCHASED_RESPONSE,
} from 'models/credits';
import { call, put, takeLatest } from 'redux-saga/effects';
import { creditsApi } from 'services/credits';
import {
  setAvailablePackage,
  setDataBalanceCredit,
  updateSubscriptionPurchased,
} from 'store/reducers/credits';
import {
  GET_CURRENT_BALANCE_CREDIT_REQUEST,
  GET_PACKAGE_AVAILABLE_REQUEST,
  GET_SUBSCRIPTION_PURCHASED_REQUEST,
} from 'store/reducers/credits/actionTypes';
import { setGlobalLoading } from 'store/reducers/global';

function* getCurrentBalanceCreditRequest(
  action: PayloadAction<PayloadActionData<{ parentId: string }>>,
) {
  try {
    const response: AxiosResponse<RESPONSE_TYPE<number>> = yield call(
      creditsApi.getCurrentBalanceCredit,
      action.payload.data.parentId,
    );
    yield put(setDataBalanceCredit(response.data.data));
    action.payload.onSuccess?.();
  } catch (error) {
    action.payload.onFailed?.(error);
  } finally {
    action.payload.onFinally?.();
  }
}

function* getPackageAvailableRequest() {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<
      RESPONSE_TYPE<PAGINATION2_TYPE<AVAILABLE_PACKAGE_ITEM_TYPE[]>>
    > = yield call(creditsApi.getPackagesAvailable);
    yield put(setAvailablePackage(response.data.data));
  } catch (error) {
    yield put(setGlobalLoading(false));
  } finally {
    yield put(setGlobalLoading(false));
  }
}

function* getSubscriptionPurchasedRequest(
  action: PayloadAction<PayloadActionData<GET_SUBSCRIPTION_PURCHASED_PARAMS>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<
      RESPONSE_TYPE<SUBSCRIPTION_PURCHASED_RESPONSE>
    > = yield call(creditsApi.getSubscriptionPurchased, action.payload.data);
    yield put(updateSubscriptionPurchased(response.data.data));
    action.payload.onSuccess?.();
  } catch (error) {
    action.payload.onFailed?.(error);
    yield put(setGlobalLoading(false));
  } finally {
    action.payload.onFinally?.();
    yield put(setGlobalLoading(false));
  }
}

function* getCredits() {
  yield takeLatest(
    GET_CURRENT_BALANCE_CREDIT_REQUEST,
    getCurrentBalanceCreditRequest,
  );
  yield takeLatest(GET_PACKAGE_AVAILABLE_REQUEST, getPackageAvailableRequest);
  yield takeLatest(
    GET_SUBSCRIPTION_PURCHASED_REQUEST,
    getSubscriptionPurchasedRequest,
  );
}

export default getCredits;
