import { Stack, Typography } from '@mui/material';
import { PaymentComponent } from 'components';
import PopupConfirm from 'components/popup/PopupConfirm';
import { useQueryString } from 'hooks';
import { PURCHASED_PACKAGE_FROM } from 'models/credits';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setIsOpenPaymentSuccess } from 'store/reducers/payments';
import { payForBuyPackageAction } from 'store/reducers/payments/actionTypes';
import { formatPrice, pluralString } from 'utils';

const PaymentCredits = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const query = useQueryString();
  const { isOpenPaymentSuccess } = useAppSelector((state) => state.payment);
  const { selectedPackageId, availablePackages } = useAppSelector(
    (state) => state.credits,
  );

  const selectedPackage = availablePackages?.records?.find((item) =>
    item.id.includes(selectedPackageId),
  );

  const handleBackAction = () => {
    if (query.type === PURCHASED_PACKAGE_FROM.JOB) {
      navigate(-1);
    } else navigate(APP_ROUTES.CREDITS.OVERVIEW.to);
  };

  const onSuccessPayment = (isPN: boolean) => {
    if (!isPN) {
      dispatch(setIsOpenPaymentSuccess(true));
    }
  };

  const getEnhancedPlan = () => {
    if (selectedPackage) {
      return +(selectedPackage.price / selectedPackage.parent_rate).toFixed();
    }
  };

  return (
    <Stack spacing={8}>
      {isOpenPaymentSuccess && (
        <PopupConfirm
          typeIcon="check"
          title="Successful Payment"
          content={
            <Typography variant="Web_Label_14">
              Your payment has been successfully made
            </Typography>
          }
          textButton={`Back to ${
            query.type === PURCHASED_PACKAGE_FROM.JOB ? 'Bookings' : 'Credits'
          }`}
          onClose={() => {
            dispatch(setIsOpenPaymentSuccess(false));
          }}
          hrefButton={
            query.type === PURCHASED_PACKAGE_FROM.JOB
              ? -1
              : APP_ROUTES.CREDITS.OVERVIEW.to
          }
        />
      )}
      <Stack spacing={1} alignItems="center">
        <Typography variant="Web_Title_22">Payment</Typography>
        <Typography variant="Web_Label_18">Buy Sitter Plan</Typography>
      </Stack>
      <React.Fragment>
        <PaymentComponent
          feeSummaryChildren={
            <Stack>
              <Typography variant="Components_Column_content" color={'#6d6d6d'}>
                Enhanced Plan - {pluralString('hour', getEnhancedPlan())}
              </Typography>
              <Typography variant="Components_Column_content" color={'#6d6d6d'}>
                {formatPrice(selectedPackage?.price)}
              </Typography>
            </Stack>
          }
          morePayload={{
            package_id: selectedPackageId,
          }}
          totalAmount={selectedPackage?.price}
          handleBackAction={handleBackAction}
          onSuccessPayment={onSuccessPayment}
          dispatchAction={payForBuyPackageAction}
        />
      </React.Fragment>
    </Stack>
  );
};

export default PaymentCredits;
