import { PayloadActionData } from 'models';
import { GET_SUBSCRIPTION_PURCHASED_PARAMS } from 'models/credits';

export const GET_CURRENT_BALANCE_CREDIT_REQUEST =
  'GET_CURRENT_BALANCE_CREDIT_REQUEST';

export const getCurrentBalanceCreditAction = (
  payload?: PayloadActionData<{ parentId: string }>,
) => ({
  type: GET_CURRENT_BALANCE_CREDIT_REQUEST,
  payload,
});

export const GET_PACKAGE_AVAILABLE_REQUEST = 'GET_PACKAGE_AVAILABLE_REQUEST';

export const getPackageAvailableAction = () => ({
  type: GET_PACKAGE_AVAILABLE_REQUEST,
});

export const GET_SUBSCRIPTION_PURCHASED_REQUEST =
  'GET_SUBSCRIPTION_PURCHASED_REQUEST';

export const getSubscriptionPurchasedAction = (
  payload?: PayloadActionData<GET_SUBSCRIPTION_PURCHASED_PARAMS>,
) => ({
  type: GET_SUBSCRIPTION_PURCHASED_REQUEST,
  payload,
});
