import {
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { APP_IMG, LogoIcon } from 'assets';
import PopupBuyCredits from 'components/popup/PopupBuyCredits';
import { PAYMENT_MODAL_TYPE, PAYMENT_RESPONSE } from 'models/payments';
import { ReactNode, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  setIsOpenBuyCredits,
  setIsOpenPaymentModal,
  updatePaymentMethodId,
} from 'store/reducers/payments';
import { getListCardsAction } from 'store/reducers/payments/actionTypes';
import { formatPrice } from 'utils';
import PaymentModal from './components/PaymentModal';
import {
  FeeSummaryContainer,
  PaymentContainer,
  PaymentMethodContainer,
  PaymentTitle,
  StyledAddCard,
  StyledCreditBalance,
  StyledFormControl,
  TotalAmount,
} from './styles';

interface PaymentComponentProps {
  totalAmount?: number;
  morePayload?: any;
  dispatchAction?: Function;
  handleBackAction?: () => void;
  onSuccessPayment?: (isPayNow: boolean) => void;
  hideTotal?: boolean;
  feeSummaryChildren?: ReactNode;
  creditBalance?: number;
}

interface PAYMENT_MODAL {
  type: PAYMENT_MODAL_TYPE;
  valueQrCode: string;
}

function PaymentComponent({
  creditBalance,
  totalAmount,
  morePayload,
  dispatchAction,
  handleBackAction,
  onSuccessPayment,
  hideTotal = false,
  feeSummaryChildren,
}: PaymentComponentProps) {
  const dispatch = useAppDispatch();
  const { isOpenPaymentModal, paymentMethodId, isOpenBuyCredits, listCards } =
    useAppSelector((state) => state.payment);
  const [paymentModalData, setPaymentModalData] = useState<PAYMENT_MODAL>({
    type: PAYMENT_MODAL_TYPE.PAY_NOW,
    valueQrCode: '',
  });
  const [value, setValue] = useState<string>(PAYMENT_MODAL_TYPE.PAY_NOW);

  const isPayNow = value === PAYMENT_MODAL_TYPE.PAY_NOW;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    if (selectedValue !== PAYMENT_MODAL_TYPE.PAY_NOW) {
      dispatch(updatePaymentMethodId(selectedValue));
    }
    setValue(selectedValue);
  };

  useEffect(() => {
    if (creditBalance < 0) {
      dispatch(getListCardsAction());
    }
    // eslint-disable-next-line
  }, []);

  const handleMakePayment = () => {
    dispatch(
      dispatchAction({
        data: {
          payment_method_id: !isPayNow ? paymentMethodId : undefined,
          payment_method_type:
            value === PAYMENT_MODAL_TYPE.PAY_NOW
              ? PAYMENT_MODAL_TYPE.PAY_NOW
              : PAYMENT_MODAL_TYPE.CARD,
          ...morePayload,
        },
        onFailed: () => {
          onSuccessPayment?.(isPayNow);
        },
        onSuccess: (data: PAYMENT_RESPONSE) => {
          if (isPayNow) {
            const { image_url_png } = data?.next_action?.paynow_display_qr_code;
            setPaymentModalData({
              type: PAYMENT_MODAL_TYPE.PAY_NOW,
              valueQrCode: !isPayNow ? '' : image_url_png,
            });
            dispatch(setIsOpenPaymentModal(true));
          }

          onSuccessPayment?.(isPayNow);
        },
      }),
    );
  };

  const handleClickAddCard = () => {
    dispatch(setIsOpenPaymentModal(true));
    setPaymentModalData({
      type: PAYMENT_MODAL_TYPE.ADD_NEW_CARD,
      valueQrCode: '',
    });
  };

  const _onClosePaymentModal = () => {
    dispatch(setIsOpenPaymentModal(false));
    setPaymentModalData({
      type: PAYMENT_MODAL_TYPE.CARD,
      valueQrCode: '',
    });
  };

  const getTotalAmount = () => {
    if (isPayNow) return totalAmount;
    return totalAmount + totalAmount * 0.03;
  };

  return (
    <PaymentContainer>
      {isOpenPaymentModal && (
        <PaymentModal
          methodType={paymentModalData.type}
          onClose={_onClosePaymentModal}
          valueQrCode={paymentModalData.valueQrCode}
        />
      )}
      <FeeSummaryContainer>
        <PaymentTitle>Fee Summary</PaymentTitle>

        {feeSummaryChildren}

        {!isPayNow && (
          <Stack>
            <Typography variant="Components_Column_content" color={'#6d6d6d'}>
              Payment GatewayFee (3%)
            </Typography>
            <Typography variant="Components_Column_content" color={'#6d6d6d'}>
              {formatPrice(totalAmount * 0.03)}
            </Typography>
          </Stack>
        )}
        {!hideTotal && (
          <Stack>
            <TotalAmount>Total Amount</TotalAmount>
            <TotalAmount>{formatPrice(getTotalAmount())}</TotalAmount>
          </Stack>
        )}
      </FeeSummaryContainer>
      <Divider sx={{ borderColor: '#D9D9D9' }} />
      <PaymentMethodContainer>
        {creditBalance >= 0 ? (
          <Stack spacing={1}>
            <StyledCreditBalance bgcolor={'light.shades4'}>
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                <LogoIcon sx={{ color: 'primary.main' }} />
                <Typography variant="Small_Body_16">Credit Balance</Typography>
              </Stack>
              <Typography variant="Small_Profile_16">
                {formatPrice(creditBalance)}
              </Typography>
            </StyledCreditBalance>
            {creditBalance < getTotalAmount() && (
              <StyledCreditBalance bgcolor={'light.shades8'}>
                <Typography variant="Components_Column_content" color="primary">
                  You have insufficient credits to accept this booking
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => dispatch(setIsOpenBuyCredits(true))}
                >
                  Buy Credit
                </Button>
              </StyledCreditBalance>
            )}
          </Stack>
        ) : (
          <>
            <Stack gap={2.25}>
              <PaymentTitle>Payment Method</PaymentTitle>
              <StyledFormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={PAYMENT_MODAL_TYPE.PAY_NOW}
                    control={<Radio />}
                    label={
                      <Stack className="payment-method-label">
                        <img src={APP_IMG.payNowIcon} alt="pay-now" />
                        <Typography>PayNow</Typography>
                      </Stack>
                    }
                  />
                  {listCards
                    ?.filter(
                      (card, index, self) =>
                        index === self.findIndex((t) => t.last4 === card.last4),
                    )
                    ?.map((card) => (
                      <FormControlLabel
                        key={card.id}
                        value={card.id}
                        control={<Radio />}
                        label={
                          <Stack className="payment-method-label">
                            <img src={APP_IMG.cardsIcon} alt="cards" />
                            <Typography>••••{card.last4}</Typography>
                          </Stack>
                        }
                      />
                    ))}
                </RadioGroup>
              </StyledFormControl>
            </Stack>
            <StyledAddCard onClick={handleClickAddCard}>
              <img src={APP_IMG.cardsIcon} alt="cards" />
              <Typography>+ Add Debit/Credit Card</Typography>
            </StyledAddCard>
          </>
        )}
      </PaymentMethodContainer>
      <Button
        variant="contained"
        fullWidth
        onClick={handleMakePayment}
        disabled={creditBalance >= 0 && creditBalance < getTotalAmount()}
      >
        Make Payment
      </Button>
      <Button variant="text" fullWidth onClick={handleBackAction}>
        Back
      </Button>
      {isOpenBuyCredits && (
        <PopupBuyCredits
          _onClosePopup={() => dispatch(setIsOpenBuyCredits(false))}
        />
      )}
    </PaymentContainer>
  );
}

export default PaymentComponent;
