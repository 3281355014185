import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AC_REQUEST_SITTER_STATUS } from 'models/overview';

export interface OverviewType {
  sitterId: string;
  acRequestStatus: AC_REQUEST_SITTER_STATUS;
}

const initialState: OverviewType = {
  sitterId: '',
  acRequestStatus: AC_REQUEST_SITTER_STATUS.PENDING,
};

const overviewSlice = createSlice({
  name: 'overview',
  initialState,
  reducers: {
    updateSitterId(state: OverviewType, action: PayloadAction<string>) {
      state.sitterId = action.payload;
    },
    updateACRequestSitterStatus(
      state: OverviewType,
      action: PayloadAction<AC_REQUEST_SITTER_STATUS>,
    ) {
      state.acRequestStatus = action.payload;
    },
  },
});

// Actions
export const { updateSitterId, updateACRequestSitterStatus } =
  overviewSlice.actions;

export default overviewSlice.reducer;
