import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { theme } from 'theme';

export const LabelTitle = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  color: ${theme.palette.label.primary};
  text-align: center;
`;

export const LabelSubTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: ${theme.palette.label.primary};
  opacity: 50%;
`;
