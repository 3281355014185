import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { ChevronDownIcon, ChevronLeftIcon } from 'assets';
import { InputField } from 'components';
import ModalUI from 'components/common/ModalUI';
import CheckboxField from 'components/form-control/CheckboxField';
import RadioGroupField from 'components/form-control/RadioGroupField';
import yup from 'config/yup.custom';
import { SYS_MESS } from 'constants/systemMessage';
import {
  ADDRESS_LIST_RESPONSE,
  ADDRESS_PARAMS_REQUEST,
} from 'models/parent/address';
import { FormEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  addAddressAction,
  editAddressAction,
  getAddressByPostalCodeAction,
  getAddressListAction,
  getRegionListAction,
} from 'store/reducers/parent/actionTypes';
import {
  FormContainer,
  SelectRegionField,
  StyledRegionList,
  StyledTitle,
} from './styles';

interface AddEditAddressProps {
  open: boolean;
  onClose: () => void;
  isEdit?: boolean;
  selectedAddress: ADDRESS_LIST_RESPONSE;
}

interface IFormAddEditAddress {
  postal_code: string;
  name: string;
  block_number: string;
  street: string;
  unit_number: string;
  building_name: string;
  region_id: string;
  is_default: boolean;
  latitude: number;
  longitude: number;
}

const schema = yup
  .object()
  .shape({
    postal_code: yup.string().required(SYS_MESS.ERROR.POSTAL_CODE_REQUIRED),
    name: yup.string().required(SYS_MESS.ERROR.ADDRESS_NAME_REQUIRED),
    block_number: yup.string().required(SYS_MESS.ERROR.BLOCK_NO_REQUIRED),
    street: yup.string().required(SYS_MESS.ERROR.STREET_ADDRESS_REQUIRED),
    unit_number: yup.string().required(SYS_MESS.ERROR.UNIT_NO_REQUIRED),
    building_name: yup.string().required(SYS_MESS.ERROR.BUILDING_NAME_REQUIRED),
    region_id: yup.string().required(SYS_MESS.ERROR.REGION_REQUIRED),
  })
  .required();

function AddEditAddress({
  open,
  onClose,
  isEdit,
  selectedAddress,
}: AddEditAddressProps) {
  const dispatch = useAppDispatch();
  const { regions, addressByCode } = useAppSelector((state) => state.parent);
  const [isChooseRegion, setIsChooseRegion] = useState<boolean>(false);
  const form = useForm<IFormAddEditAddress>({
    resolver: yupResolver(schema),
    defaultValues: {
      postal_code: '',
      name: '',
      block_number: '',
      street: '',
      unit_number: '',
      building_name: '',
      region_id: '',
      latitude: 0,
      longitude: 0,
      is_default: false,
    },
  });

  const { handleSubmit, control, getValues, setValue, reset } = form;
  const onSubmit = (data: ADDRESS_PARAMS_REQUEST) => {
    if (isEdit) {
      dispatch(
        editAddressAction({
          data: { ...data, id: selectedAddress.id },
          onSuccess: () => {
            onCloseAndResetData();
            dispatch(getAddressListAction());
          },
        }),
      );
    } else {
      dispatch(
        addAddressAction({
          data: { ...data },
          onSuccess: () => {
            onCloseAndResetData();
            dispatch(getAddressListAction());
          },
        }),
      );
    }
  };

  const onCloseAndResetData = () => {
    onClose();
    reset();
  };

  const onOpenChooseRegion = () => {
    setIsChooseRegion(true);
  };

  const onCloseChooseRegion = () => {
    setIsChooseRegion(false);
  };

  const onSetValueRegion = () => {
    if (getValues('region_id')) {
      setIsChooseRegion(false);
    }
  };

  const onGetAddressByPostalCode = () => {
    dispatch(
      getAddressByPostalCodeAction({
        data: {
          id: Number(getValues('postal_code')),
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(getRegionListAction());
  }, [dispatch]);

  useEffect(() => {
    if (addressByCode) {
      setValue('block_number', addressByCode.BLK_NO);
      setValue('building_name', addressByCode.BUILDING);
      setValue('street', addressByCode.ROAD_NAME);
      setValue('latitude', Number(addressByCode.LATITUDE));
      setValue('longitude', Number(addressByCode.LONGITUDE));
    }
  }, [addressByCode, setValue]);

  useEffect(() => {
    if (isEdit && selectedAddress) {
      setValue('block_number', selectedAddress.block_number);
      setValue('building_name', selectedAddress.building_name);
      setValue('street', selectedAddress.street);
      setValue('latitude', Number(selectedAddress.latitude));
      setValue('longitude', Number(selectedAddress.longitude));
      setValue('postal_code', selectedAddress.postal_code);
      setValue('name', selectedAddress.name);
      setValue('unit_number', selectedAddress.unit_number);
      setValue('region_id', selectedAddress.region_id);
      setValue('is_default', selectedAddress.is_default);
    }
  }, [isEdit, setValue, selectedAddress]);

  const titleSection = () => {
    if (!isChooseRegion) return isEdit ? 'Edit Address' : 'Add Address';
    return (
      <Stack flexDirection={'row'} alignItems={'center'}>
        <ChevronLeftIcon
          onClick={onCloseChooseRegion}
          sx={{
            fontSize: 30,
            cursor: 'pointer',
            paddingTop: '2px',
            transform: 'rotate(180deg)',
          }}
        />
        <StyledTitle>Region</StyledTitle>
      </Stack>
    );
  };

  return (
    <ModalUI
      open={open}
      title={titleSection() as any}
      onClose={onCloseAndResetData}
      maxW={400}
      hideCloseButton={isChooseRegion}
    >
      <FormContainer
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
      >
        {isChooseRegion ? (
          <Stack spacing={2.5}>
            <StyledRegionList>
              <RadioGroupField
                optionLabel="name"
                optionValue="id"
                name="region_id"
                control={control}
                options={regions?.rows}
              />
            </StyledRegionList>
            <Button variant="contained" fullWidth onClick={onSetValueRegion}>
              Save
            </Button>
          </Stack>
        ) : (
          <Stack spacing={1.5}>
            <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
              <InputField
                placeholder="Postal Code"
                control={control}
                name={'postal_code'}
              />
              <Button variant="outlined" onClick={onGetAddressByPostalCode}>
                Populate
              </Button>
            </Stack>
            <Divider sx={{ borderColor: '#D9D9D9' }} />
            <InputField
              placeholder="Name this address e.g. Home"
              control={control}
              name={'name'}
            />
            <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
              <InputField
                placeholder="Block no."
                control={control}
                name={'block_number'}
              />
              <InputField
                placeholder="Street Address"
                control={control}
                name={'street'}
              />
            </Stack>
            <InputField
              placeholder="Unit No. (put 01 for landed)"
              control={control}
              name={'unit_number'}
            />
            <InputField
              placeholder="Building Name (optional)"
              control={control}
              name={'building_name'}
            />
            <SelectRegionField onClick={onOpenChooseRegion}>
              <Typography variant="Components_UnstyledContainer">
                {regions?.rows.find(
                  (item) => item.id === getValues('region_id'),
                )?.name || 'Select region'}
              </Typography>
              <ChevronDownIcon />
            </SelectRegionField>
            <CheckboxField
              label={'Set as default address'}
              name="is_default"
              control={control}
            />
            <Button variant="contained" type="submit" fullWidth sx={{ mt: 5 }}>
              Save
            </Button>
          </Stack>
        )}
      </FormContainer>
    </ModalUI>
  );
}

export default AddEditAddress;
