import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DATA_CREATE_REQUEST_SUMMARY } from 'models/create-request';

export interface trialRequest {
  stepTrial: number;
  bodyCreateRequest: DATA_CREATE_REQUEST_SUMMARY;
}

const initialState: trialRequest = {
  stepTrial: 1,
  bodyCreateRequest: null,
};

const trialRequestSlice = createSlice({
  name: 'trialRequest',
  initialState,
  reducers: {
    updateBodyCreateRequest(
      state: trialRequest,
      action: PayloadAction<DATA_CREATE_REQUEST_SUMMARY>,
    ) {
      state.bodyCreateRequest = action.payload;
    },
    updateStepTrial(state: trialRequest, action: PayloadAction<number>) {
      state.stepTrial = action.payload;
    },
  },
});

export const { updateStepTrial, updateBodyCreateRequest } =
  trialRequestSlice.actions;

export default trialRequestSlice.reducer;
