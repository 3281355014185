import { METADATA, PARAMETERS } from 'models/common';

/* =========== ENUM ============ */
export enum JOB_STATUS {
  PENDING = 'pending',
  UPCOMING = 'upcoming',
  STARTED = 'started',
  CANCELLED = 'cancelled',
  PENDING_COMPLETED = 'pending completion',
  UPCOMING_PAID = 'upcoming paid',
  UPCOMING_INSUFFICIENT = 'upcoming insufficient',
  COMPLETED = 'completed',
}

/* ===========  INTERFACE ============ */
export interface EDIT_JOB_FORM {
  startDate: moment.Moment;
  endDate: moment.Moment;
  startTime: Date;
  endTime: Date;
}
export interface JOB_LIST_RESPONSE {
  metadata: METADATA;
  records: JOB_ITEM_RESPONSE[];
}

export interface JOB_ITEM_RESPONSE {
  id: string;
  total: number;
  parent_id: string;
  sitter_id: string;
  number: string;
  total_hours: number;
  start_datetime: string;
  end_datetime: string;
  children: JOB_CHILDREN_ITEM[];
  address: JOB_ADDRESS;
  special_notes: SPECIAL_NOTE;
  duration: number;
  status: JOB_STATUS;
  deleted: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  is_ac_job: boolean;
  is_trial_job: boolean;
  is_paid: boolean;
  Parent: JOB_PARENT_INFORMATION;
  Sitter: JOB_SITTER_INFORMATION;
}

export interface JOB_CHILDREN_ITEM {
  id: string;
  picture: string;
  age_range: number;
  full_name: string;
}

interface JOB_ADDRESS {
  id: string;
  name: string;
  region: string;
  street: string;
  postal_code: string;
  unit_number: string;
  block_number: string;
  Region: {
    name: string;
  };
}

interface SPECIAL_NOTE {
  '0': string;
  '1': string;
  '2': string;
}

export interface JOB_PARENT_INFORMATION {
  profile_picture: string | null;
  User: BOOKING_USER_INFORMATION;
}

interface BOOKING_USER_INFORMATION {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  email: string;
  phone_number: string;
  id?: string;
}

export interface JOB_SITTER_INFORMATION {
  salary_per_hour: number;
  rating: number;
  profile_picture: string;
  star_sitter_badge: number;
  User: BOOKING_USER_INFORMATION;
}

export interface JOB_DETAIL_RESPONSE extends JOB_ITEM_RESPONSE {}

export interface GET_LIST_JOB_PARAMS extends PARAMETERS {
  parent_id: string;
}
