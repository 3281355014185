import styled from '@emotion/styled';

export const StyledStripeForm = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  & button {
    margin-top: 15px;
  }
`;
